import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import doorSfx from "../../res/sfx/normalportal.wav";
import door from "../../res/generic_door.png";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/library.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Library = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Library_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Library_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Library</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter into a room with row on row of bookshelves stretching forward, 
        arranged parallel. They stretch so high the tops vanish into the darkness 
        and on so far that they vanish below the horizon line.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Library_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Library_Obj.route);
      }}
    >
      <img src={Library_Obj.image} />
      <span className="entryText">A portal ringed with gently glowing light.</span>
    </button>
  );
};

export var Library_Obj = {
  name: "Library",
  room: Library,
  entry: Entryway,
  route: "/halloween2023/library",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Library;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Something growls from somewhere amidst the labyrinthian shelves. <br />
        <br />
        Make an Academics (Mind) or Observation (Perception) roll here to 
        try and navigate the maze of bookshelves to avoid the source of the 
        growling. <br />
        <br />
        If you wish to try and peruse the library along the way, you may do so 
        - if you do not, then make the Academics or Observation roll with Advantage 
        and add 5 to the result.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your results below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(props.rooms[0].route);
  };
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You find your way out unharmed, successfully navigating 
          the shelves to a portal leading outwards; if you looked for something 
          along the way, you found it. DM QM : NORTHEAST if you escape with 
          it to see what you found.
        </div>
      </div>
      <DemonScript />
      <button className="inputSubmit" onClick={submitHandler}>
        Look around and see where you've found yourself.
      </button>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(props.rooms[0].route);
  };
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You find your way to the exit, but are harmed in 
          the process, taking 2d6 damage from a rabid werewolf loose in the library.
        </div>
      </div>
      <button className="inputSubmit" onClick={submitHandler}>
        Look around and see where you've found yourself.
      </button>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
