import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/snatcher.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Snatcher = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Snatcher_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Snatcher_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Snatcher</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter the room and feel something breathing down your neck, 
        the fetid stench of rotting flesh wafting over your shoulder and 
        making your stomach churn with disgust. Sickly yellow light suffuses 
        the immediate space, dim enough you can't see more than ten feet 
        in front of you.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Snatcher_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Snatcher_Obj.route);
      }}
    >
      <img src={Snatcher_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Snatcher_Obj = {
  name: "Snatcher",
  room: Snatcher,
  entry: Entryway,
  route: "/halloween2023/snatcher",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Snatcher;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        Something brushes against your back, and a shiver trails 
        down your spine; you feel your clothing dampen where contact was made.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Don't Look Behind You
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Look Behind You
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You keep your gaze fixed forward and shut your eyes, pressing onwards blindly; 
        the breath continues to breathe over your shoulder for what seems like hours 
        before you eventually feel it move away.
        <br /><br />
        Opening your eyes reveals another portal in front of you.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          See where it leads.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You glance over your shoulder; a face covered with holes, like lotus seeds or maggot pinholes in flesh, gazes back at you, your own and not your own. It bowls you over onto the floor as if you weighed nothing at all.<br />
        <br />
        It begins removing your soul through your body it skitters about like some hellspawn spider and your immobilized body is dragged with it.

      </div>

      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/possession");
        }}
      >
        Slowly, your soul is pulled away from your flesh.
      </button>
    </div>
  );
};
