import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import sfx from "../../res/sfx/Infected Mind Ambience.mp3";
import song from "../../res/music/Growing Whispers.mp3";
import banner from "../../res/correspondence.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Correspondence = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(sfx);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Correspondence_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Correspondence_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Shifting Glyphs</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Stepping through the portal leaves you in a room filled 
        bottom to top with strange, shifting carved symbols; runes 
        etched into every inch of the room squirm and writhe,
        enrapturing your gaze and making it impossible to look away.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Correspondence_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Correspondence_Obj.route);
      }}
    >
      <img src={Correspondence_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Correspondence_Obj = {
  name: "Correspondence",
  room: Correspondence,
  entry: Entryway,
  route: "/halloween2023/correspondence",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Correspondence;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        The longer you stare, the more they work their way into your mind - 
        skin begins to heat, blistering and bubbling as alien magic is 
        conducted from the sigils to your body. You feel them sear your way into your mind and know you'll never be free of the burning, stripping flames that tear you to pieces. As they build scars and wounds on your body, black ichor seeps from the wall, like tangible darkness.
        <br /><br />
        Make three Mental Acuity, Mysticism (Mind), or Spirituality (Spirit) rolls to break your gaze away.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You marshal your strength and manage to break 
          your gaze from the runes long enough to find a door hidden 
          beneath them; you open it and dart through, into a mercifully 
          dark room where portals thrum faintly.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  playOneShot(sfx);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You struggle as hard as you can, but it's only after 
          you've been burnt severely and large chunks of your body have been rendered 
          charred that you stumble through a door, away from the runes.
          <br /><br />
          You take <b>1d4 damage</b> from the burns that crisscross your body,
          the runes having etched themselves into your skin with burns and scars.{" "}
          <b>
            For the remainder of this run, roleplay yourself as seeing the runes shifting in your vision,
            gradually going mad from their influence. You suffer -1d4 on every roll until you leave the castle.
          </b>
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
