import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/closeeyes.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const CloseEyes = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(CloseEyes_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(CloseEyes_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 1;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Close Your Eyes</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        An entity lingers in this room, prowling and watching; when you close your eyes, it's almost like it doesn't notice you. 
        Open them, and you find yourself unable to process what you see, unable to look away, struggling with comprehension. 
        You feel your mind struggle to make sense of the input it's recieving, folding over on itself over and over.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={CloseEyes_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(CloseEyes_Obj.route);
      }}
    >
      <img src={CloseEyes_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var CloseEyes_Obj = {
  name: "CloseEyes",
  room: CloseEyes,
  entry: Entryway,
  route: "/halloween2023/closeeyes",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default CloseEyes;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You weep. The wish that you feel that isn't your own is to claw your eyes out - and you feel thousands of tiny legs skittering over your skin, an infinite number of bites peppered across you.
        <br /><br />
        Roll a Mental Acuity to resist doing so. 
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your result below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You force your eyes shut and pass through this room, 
          steeling yourself and willing your mind to resist the urge to peek 
          - you receive advantage on all rolls made in the next two rooms 
          of the maze as your focus hones to a razor's edge.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(props.rooms[0].route);
  };
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You can bear it no longer - you claw 
          at your eyes to avoid seeing the thing any more, 
          the agony of losing vision eclipsed by the relief you feel at no longer seeing it. 
          You are not fully blind - but your eyes have been damaged severely.
          <br />
          <br />
          <b>You take 1d6 damage, are Blinded until your eyes are regrown, and roll with Disadvantage 
          on all rolls that require sight in the maze. </b>
        </div>
      </div>
      <button className="inputSubmit" onClick={submitHandler}>
        Fumble your way forward, trying to find a portal.
      </button>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
