import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../gui/difficultyMeter";
import doorSfx from "../res/sfx/Misc II.wav";
import song from "../res/music/possession.ogg";
import banner from "../res/possession.jpg";
import { changeSong, GlobalVars, playOneShot } from "./room_handler";

const bannerUrl = banner;

const Possession = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(doorSfx);
  }, []);
  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Possession</h1>
      <img className="banner" src={bannerUrl} />
      <div className="descriptionText">
        <h2 className="PossessionText">IN THE DARK WHERE SHADOWS DWELL, AN INTERLOPER RISES WELL</h2>
        You feel your consciousness roughly ejected from your body 
        - and something else takes its place. Your body's injuries heal up as this happens, 
        and your imprisoned spirit watches a portal appear which allows your 
        possessed body to return to the temple at large.<br />
        <br />
        For the next hour, role play this spirit possessing your body. 
        It is a servant of Alethea spawned by dark magic from the darkest parts of your 
        character’s personality - its goals are to sow strife, discord, 
        and generally cause problems and impede the process of those trying to rescue Khronica. 
        How you go about this is up to you, just keep in mind that this is essentially 
        an evil version of your character - or alternatively, a version that serves Alethea.<br />
        <br />
        Once the hour ends, the spirit possessing your body fades and you regain control of your body.<br />
        <br />
        The only limitation on this is that if you wish to conduct PvP, you must obtain explicit OOC permission first.
        <br />
        <br />
        [Experience created by Larethvar Lightwing, Rosen Valdemar, Carissen Rixen, Adeline Khan, Erik Manley, and Farwulf Svarti]
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2023/");
          }}
        >
          Return to the temple.
        </button>
      </div>
    </div>
  );
};

export default Possession;
