import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/originals/c9/f3/8b/c9f38b02081c659f9c29cd90513f4e72.jpg";
const doorUrl = door;

var nextRooms;

const Holewall = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Holewall_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Holewall_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Holes</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Before you, a small door, enough to squeeze your way through, 
        is set firmly into the wall. Along the surface of the wall, 
        dozens of small, unlit holes sit, just large enough to fit ones 
        hand into. You have the feeling that one of these might control 
        the door, while the others very well might free your extremeties, 
        free of charge.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Holewall_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Holewall_Obj.route);
      }}
    >
      <img src={Holewall_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Holewall_Obj = {
  name: "Holewall",
  room: Holewall,
  entry: Entryway,
  route: "/halloween2023/holewall",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Holewall;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">Whispers emanate from the holes, and rivers of dark ichor run down the wall, scarring and burning with agonizing hot and cold where they touch.
      <br /><br />
      Roll three Observation (Perception) or Academics (Mind) rolls to find the right hole to reach into.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You find the least disturbing and bloodstained hole with intensive deduction or observation, before reaching your hand in, and grabbing the knob - <br /><br />
          CLICK!<br /><br />
          The door swings open, and your hand seems no worse for wear other than covered in a few cobwebs. Beyond the door are two portals.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You stick your hand forward into one of the holes and flip a switch that causes the door to swing open, but - <br />
          Something deep within grabs your hand back, pulling you forward, closer to the wall. 
          Screams emanate from all of the holes at once, reverberating in your ears, making you dizzy, 
          but not dizzy enough to miss the feeling of something biting down on your hand, hard.
          <br /><br />
          You manage to yank your hand free, but not without the injury to the appendage, dealing you {" "}<b>2d6 damage</b>, 
          and the ringing in your ears causes unsteady feet, as the screams cease, leaving only a fading cackle to be heard behind the hole riddled wall.<br />
          Beyond the door lie two portals.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
