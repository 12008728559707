import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/guard.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Guard = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Guard_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Guard_Obj);
  }

  const parseInput = (input) => {
    let rollTierOne = 3;
    let rollTierTwo = 4;
    let successes = 0;
    let difficultyOne = getDifficulty(rollTierOne);
    let difficultyTwo = getDifficulty(rollTierTwo);
    for (let i = 1; i < input.length; i++) {
      if (input[i] >= difficultyOne && input[0] == "rollOne") {
        successes += 1;
      } else if (input[i] >= difficultyTwo && input[0] == "rollTwo") {
        successes += 1;
      }
    }
    if (successes >= 3 && input[0] == "rollOne") {
      changeDisplay("pass");
    } else if (input[0] == "rollOne") {
      changeDisplay("fail");
    } else if (successes >= 1 && input[0] == "rollTwo") {
      changeDisplay("passTwo");
    } else {
      changeDisplay("failTwo");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView output={parseInput} />;
        break;
      case "passTwo":
        component = <PassTwo rooms={nextRooms} />;
        break;
      case "failTwo":
        component = <FailTwo rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Mutated Guard</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Black tar drips from the ceiling, down the walls, and oozes up through the cobble of the floors. A malevolent and wicked aura fills the air around you, like something ancient waking from a deep slumber; from the dark arches lurches a malformed humanoid, wielding an oversized butcher's cleaver. Face is covered in fleshy growths, and lumpy, bulging flesh sloshes and threatens to fall to the floor below as it lurches, like rotted meat.

      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Guard_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Guard_Obj.route);
      }}
    >
      <img src={Guard_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Guard_Obj = {
  name: "Guard",
  room: Guard,
  entry: Entryway,
  route: "/halloween2023/guard",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Guard;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output(["rollOne", input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
          It rushes towards you, hand outreached in an effort to grab ahold of you.
          <br /><br />
          Make three Agility (Dexterity) or Stealth (Dexterity) roll to dodge its grasp.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You dodge around the beast and manage to rush past it, sprinting onwards into the darkness; by the time you're forced to stop, chest heaving, you've lost the monster and have found yourself in front of two portals.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output(["rollTwo", input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You are unable to dodge, and the beast grabs you with one deformed, muscular arm. It hacks away at you violently with the cleaver, dealing deep gouges to you and severing chunks of flesh, until you collapse to the floor bleeding heavily. It walks away, thinking you dead.{" "}<br /><br />
          <b>Take 1d4 damage</b> and immediately make a Non-Magical Healing (Spirit), or Light check to staunch the heavy bleeding.
        </div>
      </div>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your result below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassTwo = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You manage to stop the violent bleeding before you bleed to death.
          <br />
          <br />
          Despite your wounds, you manage to get up and limp onwards to find two more portals.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailTwo = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You fail to stop the bleeding and are only able to stem the flow of blood a little. For the next 1d4 rooms, take 1d4 damage each time you enter a new room. Until you leave the maze, take an extra 1 damage whenever you take damage.
          <br />
          <br />
          Despite your wounds, you manage to crawl onwards to the next portals.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
