import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/nautilus.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = 
  "https://i.pinimg.com/564x/df/6e/b8/df6eb8e92ff4f48d94e1dd9cbb869a35.jpg";

var nextRooms;

const Nautilus = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Nautilus_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Nautilus_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
      case "partTwo":
        changeDisplay("partTwo");
        break;
      case "partThree":
        changeDisplay("partThree");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne output={parseInput} />;
        break;
      case "partTwo":
        component = <PartTwo output={parseInput} />;
        break;
      case "partThree":
        component = <PartThree rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Living Walls</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You find yourself in a narrow passage. It's dark and damp and tight. 
        Something seems to breathe around you as you progress inwards, deeper 
        and deeper, the walls slowly pulsating and thrumming as you venture 
        further into the organo-architecture.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Nautilus_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Nautilus_Obj.route);
      }}
    >
      <img src={Nautilus_Obj.image} />
      <span className="entryText">The portal seems to be made of flesh, and opens as you draw near.</span>
    </button>
  );
};

export var Nautilus_Obj = {
  name: "Nautilus",
  room: Nautilus,
  entry: Entryway,
  route: "/halloween2023/nautilus",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Nautilus;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        The walls only seem to narrow as you move onwards, and tendrils of 
        slime begin to drip onto your clothing; you can feel the ground 
        beneath your feet pulsating rhythmically, like the heartbeat of a 
        colossal animal.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Advance down the hallway, crouching to avoid the ceiling.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        Further on, you find your passage blocked by what appears to be a wall of bone; 
        to make it past, you need to press yourself between it and the damp ground, 
        crawling beneath it. Slime coats every inch of skin and clothing, your hair 
        clumping together, knotting and sticking to both you and the rough bone 
        suspended over you.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Crawl on your belly and try to not get stuck.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartTwo = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partThree":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You can feel the weight of the wall bearing down on you from above, 
        and you don't even have room to breathe you're so tightly wedged in. 
        More than once, you get stuck, panic rising in the back of your mind 
        as you try to unwedge yourself. <br />
        <br />
        The shaking of the environment does nothing to help either 
        - you swear on more than one occasion the strange structure 
        you crawl beneath is going to collapse, burying you alive beneath it.<br />
        <br />
        You're trapped momentarily - then the wall finally does collapse. You are buried alive beneath it, body crushed in excruciating pain as you lay there for hours, your oxygen slowly running out until you finally suffocate to death, with no hope of freedom...<br />
        <br />
        You wake with a scream and cold sweat. It was only a nightmare - but a nightmare you are terrified might come true.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Hold your breath for the last stretch, as the bone scrapes your back raw.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PartThree = (props) => {
  return (
    <div>
      <div className="roomText">
        Finally, you get out from underneath it; you stand in a fleshy ampitheater 
        of sorts, operating tables and inscrutable arcane tools scattered around. 
        Three portals lie open on one end of the room, leading out; 
        you hear commotion in the adjacent rooms. You shouldn't linger.
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
