import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/blackhole.jpg"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const BlackHole = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(BlackHole_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(BlackHole_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Blackhole</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">You exit the portal and are assaulted by searing radiation, the nimbus of a star coiling around a black hole visible far, far below you. 
      Platforms formed of crumbling marble are your only foothold, ancient mosaics inset into the stone long since rendered colorless by stellar radiation. </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={BlackHole_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(BlackHole_Obj.route);
      }}
    >
      <img src={BlackHole_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var BlackHole_Obj = {
  name: "BlackHole",
  room: BlackHole,
  entry: Entryway,
  route: "/halloween2023/blackhole",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default BlackHole;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">The world shakes and quivers around you, the void of space beginning to unwind and undo itself, stars fading in and out as the magic of the maze begins to collapse.<br /><br /> 
      Gravity tugs on your body, disparate platforms marking a spotty, constellation path from where you now stand to the portals that will lead you out of the irradiating expanse of space. 
      <br /><br />
      Make three Athletics (Body) or Agility (Dexterity) rolls to leap between the platforms to the way out.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Rolls
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> Stones give way, but you manage to leap from them before they plummet too far. 
          You feel the tug of gravity but manage to resist it, whether through strength or dexterity - at last you arrive at the end, 
          sinking to your knees in relief as anxious laughter escapes as a symptom of your distress resolving.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b>  Navigation through the platforms is difficult, feet finding purchase on the stone bleached and worn smooth by solar winds - <br /><br />
          Your feet slip. <br /><br />
          Perhaps you misgauged a gap, or a stone gave way unexpexctedly, but you begin to plummet downwards, feet over head. As you cross the event horizon, everything begins to be stretched out - 
          the world beyond the event horizon grinds to a halt as light struggles to reach you, and you feel yourself stretched out, more and more, until you are a thousand-thousand miles long and death 
          finally releases you from the sensation of being extruded and extended like pasta through a wringer.
        </div>
      </div>


      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
