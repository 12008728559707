import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/keyportal.jpg";
import doorSfx from "../../res/sfx/Metal Rusty 2_3.wav";
import song from "../../res/music/waterkey.ogg";
import banner from "../../res/harmony.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GetRoomOfType,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const WaterKey = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(WaterKey_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(WaterKey_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "red":
      case "violet":
        changeDisplay("exit");
        break;
      case "purple":
      case "black":
        changeDisplay("death");
        break;
      case "blue":
        GlobalVars.keys += 1;
        changeDisplay("continue");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "exit":
        component = <TranspOut rooms={nextRooms} />;
        break;
      case "death":
        component = <TranspDeath rooms={nextRooms} />;
        break;
      case "continue":
        component = <Continue rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Monoliths</h1>
      <img className="banner" src={bannerUrl} />
      <div className="descriptionText">
        As you step into the room, the portal closes behind you. You stand at the 
        center of what appears to be an ancient temple, ceiling and ground adorned with 
        ornate geometric patterns formed from colorful marble and mosaic glass.
        <br /><br />
        Surrounding you are seven monoliths, each bearing intricate glyphs carved into 
        surface and in a different material.
        <br /><br />
        Monolith Descriptions:
        <ul>
          <li>
            <b style={{ color: "red" }}>Red Monolith:</b> The red monolith is made 
            from some sort of metal that glows red hot. Carved into the surface are intricate 
            scenes of weapons and armor being forged, and the air around it shimmers with heat.
          </li>
          <li>
            <b style={{ color: "blue" }}>Blue Monolith:</b> The blue monolith is 
            made of tarnished silver ornamented with lapis inlays, water beading 
            on the surface in spite of the dry air and running down to the floor. 
            Inlaid into the surface are carvings of vast fleets of ships, sails unfurled.
          </li>
          <li>
            <b style={{ color: "green" }}>Green Monolith:</b> The green monolith is 
            covered from floor to top by verdant green vines, filling the air 
            near it with the faint scent of lush greenery. You can't see whatever is 
            carved into it beneath the greenery.
          </li>
          <li>
            <b style={{ color: "rgb(204, 204, 255)" }}>Violet Monolith:</b> The violet monolith 
            is covered in draping, flowing purple silk that seems to sway in a breeze 
            that comes from nowhere. Where the silks move to the side in the breeze, 
            you can see what appears to be carvings of books inset into the surface, 
            titles illegible.
          </li>
          <li>
            <b style={{ color: "white" }}>White Monolith:</b> The white monolith 
            is formed of a large slab of pure white marble. Set into the surface are 
            carvings of golden gates, decorated in detailed, intricate ancient runes 
            that you don't understand.
          </li>
          <li>
            <b style={{ color: "grey" }}>Black Monolith:</b> The black monolith is 
            carved of a massive piece of basalt, and the upper right corner seems to have 
            crumbled a little. Light seems to be absorbed into the monolith where it strikes, 
            and etched into the surface are repeating patterns of wicked, curved knifes.
          </li>
          <li>
            <b style={{ color: "rgb(74, 0, 109)" }}>Purple Monolith:</b> The purple 
            monolith emits a faint scent of ozone, the air around it charge with static. 
            The material seems newer somehow, and into the surface are etched elaborate 
            mathematical calculations.
          </li>
        </ul>
      </div>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={WaterKey_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(WaterKey_Obj.route);
      }}
    >
      <img src={WaterKey_Obj.image} />
      <span className="entryText">
        A portal wreathed in warm radiance.
      </span>
    </button>
  );
};

export var WaterKey_Obj = {
  name: "WaterKey",
  room: WaterKey,
  entry: Entryway,
  route: "/halloween2023/monolith",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default WaterKey;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [inertText, setInertText] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option == "green") {
      setInertText(
        "You touch the " + option + " monolith. Nothing appears to happen."
      );
    } 
    
    else if (option == "white") {
      setInertText(
        "You touch the " + option + " monolith. A surge of magic rushes through you, healing and powerful - you are restored to full health, and any injuries you've sustained in the maze are healed."
      );
    } 
    
    else {
      props.output([option]);
    }
  };

  return (
    <div>
      <div className="roomText">
          As you take a step forward, a booming chorus of voices, 
          masculine, feminine, and androgynous, echoes through the 
          temple. It chants: 
        <br />
        <div className="readingText">
          Seven times seven shall lead you through,<br />
          Eight of red, green, violet, and blue.<br />
          White and black do dual conflicts breed,<br />
          Scattered are lightning's double frequencies.<br /><br />

          To weave life should be sanction secure,<br />
          But one leads to loss and the other a cure.<br /><br />

          Shifting magma do eruptions create,<br />
          The freedom of fire and inertness of plates.<br /><br />

          Gaia's stone pillars do a pathway procure,<br />
          Either to healing or dormancy assured.<br /><br />

          Blood's dreadful art may broker hate,<br />
          But either a slave or a victor is its fate.<br /><br />

          Electricity may dance about with allure,<br />
          From ensnarement it provides a swift coming cure.<br /><br />

          The flap of a butterfly may alter set fate,<br />
          Through newly shifted odds or wind's freedom irate.<br /><br />

          Heed these words well or forever be entombed,<br />
          With iron's dead queen and sea's empress exhumed.<br /><br />

          See this art well and wisely make your choice,<br />
          Heed the pull of magic and the ancient's voice.<br /><br />
        </div>
      </div>
      <div className="secondaryText" color="orange">
        {inertText}
      </div>
      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="red"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Touch the Red monolith.
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="green"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Touch the Green monolith.
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="violet"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Touch the Violet monolith.
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="blue"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Touch the Blue monolith.
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="black"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Touch the Black monolith.
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="white"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Touch the White monolith.
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="purple"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Touch the Purple monolith.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const TranspOut = (props) => {
  const navigate = useNavigate();
  const room = GetRoomOfType("exit");
  return (
    <div>
      <div className="roomText">
        As you touch the monolith, you are transported elsewhere.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(room.route);
          }}
        >
          Look around at where you are.
        </button>
      </div>
    </div>
  );
};

//input rooms
const TranspDeath = (props) => {
  const navigate = useNavigate();
  const room = GetRoomOfType("death");
  return (
    <div>
      <div className="roomText">
        As you touch the monolith, you are transported elsewhere.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(room.route);
          }}
        >
          Look around at where you are.
        </button>
      </div>
    </div>
  );
};

//input rooms
const Continue = (props) => {
  return (
    <div>
      <div className="roomText">
        As you touch the monolith, water begins to rush down the surface. The room is rapidly filled, 
        first up to your ankles, then your knees. Higher and higher it rises until it begins to 
        lift you up - you float higher and higher, the monoliths rapidly vanishing from sight.
        <div style={{ paddingTop: "0vh" }}>
          <br />
          As you finally rise to the very top of the temple, you spot something embedded in the ceiling. 
          A crystal, roughly a foot across, glittering faintly. You're able to swim your way over to it 
          and pry it loose from the ceiling, at which point the water begins to drop. You eventually are returned 
          to the temple floor, the crystal held close as your feet make contact with the ground once again.
          <div className="keyAcquire">
            The crystal crumbles away in your hand, leaving you with seven multicolored fragments and five rasps. The fragments 
            are part of the final crystal you will need - if you manage to escape the maze with them, DM QM : NORTHEAST to tell him as much.
          </div>
          After your feet make contact with the temple floor once more, a flash of 
          magic emits from the crystal. Three portals open around you, offering paths onward 
          from the room you're in.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
