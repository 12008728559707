import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import sfx from "../../res/sfx/Dogs in Chains.mp3";
import banner from "../../res/friend.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Friend = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    //playOneShot(sfx);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Friend_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Friend_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Your Friend</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        As you enter the room, you're immediately surrounded by an eerie, dimly lit space that seems to stretch on into infinity. The walls are clad in deep, inky black, and adorned with luminescent paint that mimics the appearance of distant stars, casting an eerie, cold glow. The faux stars seem to twinkle and shimmer, creating the unsettling impression of being adrift in the vast expanse of outer space.

      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Friend_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Friend_Obj.route);
      }}
    >
      <img src={Friend_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Friend_Obj = {
  name: "Friend",
  room: Friend,
  entry: Entryway,
  route: "/halloween2023/friend",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Friend;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You can feel it - there's something in the room with you. Always in your peripheral vision but never in sight no matter how fast you go. You should stop, shouldn't you? Your friend wants to say hello. 
        <br /><br />
        Don't ignore your friend. 
        <br /><br />
        Don't ignore me. 
        <br /><br />
        Look at me. 
        <br /><br />
        LOOK AT ME. 
        <br /><br />
        Roll three Mental Acuity or Endurance (Body) rolls to resist the urge to look upon your friend. 
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You resist the urge to turn your gaze, focused soley on finding a way out. Soon enough you feel a buzzing noise and tingling sensation - portals, though they seems to blend in with the room itself like some sort of mirage. 
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> Your eyes slowly turn and look upon your friend. From between the darkness filling the space between the faux stars emerges a distorted face, bearing a too wide smile and sunken eyes. A scream erupts and you feel your mind split, eyes closing reflexively from the pain as you take 2d8 damage. When you open them, the beast is gone. If you survive, two portals lie in the room, now bereft of false starlight.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
