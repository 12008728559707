import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/tentacles.jpg"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Tentacles = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Tentacles_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Tentacles_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Hall of Eboncrystal</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">Lavish, ornate black crystal reaches from polished floors to the ceiling high above, pillars standing like frozen pitch to support 
      the glass ceiling, betraying an unfamiliar expanse of stars spanning overhead.</p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Tentacles_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Tentacles_Obj.route);
      }}
    >
      <img src={Tentacles_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Tentacles_Obj = {
  name: "Tentacles",
  room: Tentacles,
  entry: Entryway,
  route: "/halloween2023/tentacles",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Tentacles;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
        <p className="roomText">Screams ring through the air like screeching iron - the world shakes and shatters, falling apart like flesh under the assault of maggots.<br /><br /> 
        You step forward, and the ground begins to shift - inky tentacles pierce the crystalline floor and lunge for you, attempting to grapple and entrap you.
        <br /><br />
        Make an Athletics (Body) or Agility (Dexterity) roll to weave and avoid the tentacles so you can escape.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the result of your check below.
          <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You are faster than the appendages reaching from the pitch-crystal floor, darting through the field and finding your way to the other side. 
          Behind you, the ground churns and surges and finds itself tossing and turning with a sort of rancor over losing you. 
          <br />
          <br />
          Best not to tarry too long here.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You rush onwards with every ounce of strength you can muster, attempting to weave around the tentacles as you dart onwards in the frozen starlight, but your efforts are in vain. 
          Slick tentacles wind around you, each limb entrapped by the slimy, fleshy limbs; they pull you downwards until something tarlike rushes over your face, freezing like pitch and entrapping you. 
          The world is horribly claustrophobic in the moments before you mercifully fade from consciousness.
        </div>
      </div>


      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
