import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/keyportal.jpg";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/maintheme.mp3";
import song2 from "../../res/music/windkey.ogg";
import banner from "../../res/cavebanner.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const DarkKey = () => {
  const [display, changeDisplay] = useState("pre");
  const [attempts, setAttempts] = useState(3);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(DarkKey_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(DarkKey_Obj);
  }

  const parseInput = (input) => {
    let percTier = 4;
    let optionOneTier = 5;
    let optionTwoTier = 5;
    let optionThreeTier = 5;
    let difficulty;
    switch (input[0]) {
      case "perc":
        difficulty = getDifficulty(percTier);
        if (input[1] >= difficulty) {
          setAttempts(3);
          changeDisplay("perc");
        } else {
          setAttempts(1);
          changeDisplay("perc");
        }
        break;
      case "optionOne":
        difficulty = getDifficulty(optionOneTier);
        for (let i = 1; i < input.length; i++) {
          if (input[i] >= difficulty) {
            changeDisplay("optionOnePass");
            break;
          } else {
            changeDisplay("optionOneFail");
          }
        }
        break;
      case "optionTwo":
        difficulty = getDifficulty(optionTwoTier);
        for (let i = 1; i < input.length; i++) {
          if (input[i] >= difficulty) {
            changeDisplay("optionTwoPass");
            break;
          } else {
            changeDisplay("optionTwoFail");
          }
        }
        break;
      case "optionThree":
        difficulty = getDifficulty(optionThreeTier);
        for (let i = 1; i < input.length; i++) {
          if (input[i] >= difficulty) {
            changeDisplay("optionThreePass");
            break;
          } else {
            changeDisplay("optionThreeFail");
          }
        }
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "perc":
        changeSong(song2);
        component = (
          <PostPerceptionView output={parseInput} attempts={attempts} />
        );
        break;
      case "optionOnePass":
        component = <PassOptionOneView rooms={nextRooms} />;
        break;
      case "optionOneFail":
        component = <FailOptionOneView rooms={nextRooms} />;
        break;
      case "optionTwoPass":
        component = <PassOptionTwoView rooms={nextRooms} />;
        break;
      case "optionTwoFail":
        component = <FailOptionTwoView rooms={nextRooms} />;
        break;
      case "optionThreePass":
        component = <PassOptionThreeView rooms={nextRooms} />;
        break;
      case "optionThreeFail":
        component = <FailOptionThreeView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>A Dark Cave</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        As the portal closes behind you, you find yourself alone, standing before 
        an underground lake. The air is unmoving, stale with the scent of still
        water and air that hasn't felt the touch of sun or wind in centuries. 
        Behind you is a crumbled archway, jet black rock sunken to the floor, 
        any architectural stylings or designs having been worn away by condensation
        dripping from above.
        <br /><br />
        You spy something glinting at the bottom of the lake - a large crystal... but something seems off.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={DarkKey_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(DarkKey_Obj.route);
      }}
    >
      <img src={DarkKey_Obj.image} />
      <span className="entryText">A portal wreathed in warm radiance.</span>
    </button>
  );
};

export var DarkKey_Obj = {
  name: "DarkKey",
  room: DarkKey,
  entry: Entryway,
  route: "/halloween2023/lake",
  exits: 2,
  prob: 0.5,
  image: doorUrl,
};

export default DarkKey;

const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output(["perc", input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You reach down towards the water - with how far away the crystal seems to be,
        it should only be a short dive, but your skin sizzles as you draw near - the lake is
        enchanted with powerful curses laid over a veil of Dark magic - such powerful Dark magic 
        that even the undead are harmed by it.
        <br /><br />        
        In order to try and retrieve it, fortifying yourself will be critical. Roll either a Light (Mind or Spirit) roll to
        weave protective Light magic around yourself, Dark (Mind or Spirit) to try and push the magic out of the way, or
        Endurance (Body) to prepare to endure the damage.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your result below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PostPerceptionView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState(0);
  const [input2, setInput2] = useState(0);
  const [input3, setInput3] = useState(0);
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "" || (input == "" && input2 == "" && input3 == "")) {
      setErrText("");
      props.output([option, input, input2, input3]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        As you prepare yourself, the magic shrouding the cave begins to engage 
        and draw about you, crashing down on you with intent to slay you before you
        even have the chance to try for the crystal. Spectres of the long dead, former
        victims of Alethea, attempt to claim you, to slay you and sap your vitality.
      </p>

      {props.attempts > 1 ? (
        <span>
          Your preparation is strong enough that you buy yourself some time from 
          the spectres - you have enough time to try for the crystal thrice before 
          they slay you.
        </span>
      ) : (
        <span>
          The spectres crash down upon you and your protections falter - you only 
          have enough time to try for the crystal once before you succumb to the wicked magic 
          of the room.
        </span>
      )}

      <p className="roomText">
        Make an Endurance (Body) check to resist the lake through brute force as you dive, 
        or an Agility (Dexterity) to swim more agile in your efforts to retrieve the crystal,
        or Academics (Mind) to find a clever way of retrieving the crystal while endangering yourself 
        to a minimal degree!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Choose one of the three skill check types and make all allowed checks
          using that skill. Input the values in the boxes below and click the
          corresponding button.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        {props.attempts > 1 ? (
          <div>
            <input
              className="inputField"
              type="number"
              min="-5"
              max="60"
              onChange={(e) => {
                setInput2(e.target.value);
              }}
            ></input>
            <input
              className="inputField"
              type="number"
              min="-5"
              max="60"
              onChange={(e) => {
                setInput3(e.target.value);
              }}
            ></input>
          </div>
        ) : (
          ""
        )}
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Submit Endurance (Body) checks
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Submit Agility (Dexterity) checks
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Submit Academics (Mind) checks
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassOptionOneView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> The lake is colder than glacier water as you dive 
          into it, sapping at your life force to an alarming degree. Frostbite 
          rapidly begins to set in, cold burns causing skin to welt and rapidly decay.
          You take{" "} <b style={{ color: "red" }}>2d6 damage. </b> 
          You can feel the spectres attempting to tear you apart and keep you away from the crystal,
          and they nearly succeed, but you overpower them and your hands close on the crystal.
          <br /><br />
          If you survive the damage, you manage to swim to the surface, where the crystal emits a flash 
          of magic that dispels the spectres and opens two portals.

        </div>
        <div className="keyAcquire">
            The crystal crumbles away in your hand, leaving you with seven multicolored fragments and five rasps. The fragments 
            are part of the final crystal you will need - if you manage to escape the maze with them, DM QM : NORTHEAST to tell him as much.
        </div>
        <br />
        <br />
        Two portals lie before you.
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionOneView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You dive, struggling to make your way to the bottom, 
          but the darkness is choking and thick and you struggle to make headway. 
          The spectres above you begin to make contact, draining your life essence 
          away from your body with every touch. Your body begins to shrivel and decay, 
          growing thin and incresingly frail, and burning, freezing blisters begin to crisscross 
          your legs.
          <br /><br />
          You begin to move upwards, towards the surface, and the crystal begins to grow farther 
          and farther away from you. Tears prick at your eyes and your struggles begin to be in vain, 
          and finally you are too weakened and you open your mouth, your lungs screaming for breath.
          <br /><br />
          As the darkness and water pour into your lungs, you begin to spasm and flail, drowning - 
          no one is there to hear your flailing in the water, unable even to scream as your chest burns 
          and you feel like there are knives inside of you.{" "}
          <b>Eventually, the blissful escape of death engulfs you.</b>
          <h2 className="deathText">You are dead.</h2>
          You are revived in a random part of the maze with your memories
          intact. You must wait an hour before trying again.
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2023/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const PassOptionTwoView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You arc through the air as you dive downwards into the 
          lake, your form piercing the water like a meteor as you sink rapidly, propelled 
          by your momentum down towards the lakebed; you take {" "} <b style={{ color: "red" }}>2d6 damage</b> from touching the lake. Your form is skilled and that takes you far, 
          allowing you to make it almost all the way down to the crystal in a single go. As the spectres 
          attempt to grasp you, you weave out of the way of them, until at last your hands close over the crystal. 
          You allow it to pull you up, up, up, until you breach the surface of the lake. <br />
          As you resurface, the crystal emits a flash of magic that dispels the spectres and opens two portals.
        </div>
        <div className="keyAcquire">
          The crystal crumbles away in your hand, leaving you with seven multicolored fragments and five rasps. The fragments 
          are part of the final crystal you will need - if you manage to escape the maze with them, DM QM : NORTHEAST to tell him as much.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionTwoView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> Your efforts are flawed in some way as you try 
          to make your way down to the crystal. Perhaps your diving form is 
          unskilled or your strokes are sloppy as you swim, or perhaps the spectres 
          catch ahold of you as you sail through the air and their grasp restricts 
          your limbs. Whatever the case, you stall in the water, skin rapidly covering 
          in frostbitten burns and welts. Your vitality is sapped and your form begins 
          to shrivel up, like a carcass in the desert sun, and you begin to drown, chest 
          burning with agony as water rushes into your lungs. On the verge of death, you 
          petrify, turning to stone, silently screaming in agony for years {" "}
          <b>until the currents break the statue of your body and release you from your torment.</b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2023/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const PassOptionThreeView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You manage to find a clever way of retrieving the crystal.
          Roleplay what that method was. You manage to minimize your contact with the 
          cursed lake, and so you only take {" "}<b style={{ color: "red" }}>1d6 damage. </b> 
          After retrieving it, the crystal emits a flash of magic that dispels the spectres and opens two portals.
        </div>
        <div className="keyAcquire">
            The crystal crumbles away in your hand, leaving you with seven multicolored fragments and five rasps. The fragments 
            are part of the final crystal you will need - if you manage to escape the maze with them, DM QM : NORTHEAST to tell him as much.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionThreeView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> Your composure begins to crack as you attempt to retrieve 
          the crystal, trying desperately to pry it from the lakebed, but your efforts 
          aren't quick enough and the spectres begin to take hold of you. Your limbs begin 
          to slow as each spectral talon drains away a portion of your vitality, 
          rapidly causing you to shrivel up like a carcass in the desert sun. If you are 
          able to, you age, years of your lifespan gone in mere seconds, and slowly stone 
          begins to creep up your form from your feet, ascending upwards to your head.
          <br /><br />
          Eventually, you are completely imprisoned in stone - but you do not die. You 
          survive there, imprisoned in stone for what seems to be thousands of years, unable 
          to move a single inch or say anything.{" "} 
          <b>Your suffering only finally ends after the dripping condensation erodes your neck away, and 
            your stone head falls to the floor below, where it smashes open like a pumpkin.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2023/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
