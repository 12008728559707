import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Haunting Ambience B.mp3";
import banner from "../../res/gallery.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Hofmirrors = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Hofmirrors_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Hofmirrors_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Hall of Mirrors</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
      The walls, ceiling, and floor are clad in tarnished, cracked mirrors, 
      their surfaces mottled with dark, malevolent stains that seem to writhe 
      and pulse like the entrails of some unspeakable cosmic beast. The glass 
      appears ancient and warped, as if it holds the reflections of countless 
      souls that were lost within the maze or bound to the Devalaya.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Hofmirrors_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Hofmirrors_Obj.route);
      }}
    >
      <img src={Hofmirrors_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light;
      </span>
    </button>
  );
};

export var Hofmirrors_Obj = {
  name: "Hofmirrors",
  room: Hofmirrors,
  entry: Entryway,
  route: "/halloween2023/hofmirrors",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Hofmirrors;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        With every step, your own image is twisted and distorted, revealing 
        nightmarish incarnations of your innermost fears and desires. In one 
        reflection, your visage is contorted into a grotesque mockery, 
        a deformed nightmare of jagged teeth and hollow, soulless eyes. In another, 
        you witness a visage of your own demise, a vision of your body mutilated and broken, 
        lying in a pool of inky darkness. Slowly it gets up and stands before you in the mirror. 
        It feels both infront of you, and behind you at the same time. 
        <br /><br />
        Make three Mental Acuity, Spirituality (Spirit), or Mysticism (Mind) checks to prevent this horror from warping your reality.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> The walls, ceilings, and floors explode into showers of 
          shattered glass, raining down upon the floor with a violent cascade of glass as the 
          illusion is dispelled. Through the fragments, a path 
          onwards to the portals at the end of the room is revealed.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> The mirrors feel like they're collapsing in on you, 
          encroaching with harrowing visions. You watch millions of realities and instances 
          of your grisly end swirl before your eyes in flashes of light. You feel your mind 
          tearing itself asunder, your conscience ripping apart under the weight of the horrors, 
          rushing into the deepest recesses of your mind in an effort to hide. Screams howl through the air, 
          resonating through your ears, your own screams slamming against your eardrums.
          <br /><br />
          And then, you have reached the portals, the mirrors behind you. The screams have faded, 
          your mind clearing, but the violent and twisted echos of your visions still seem to resonate in your mind, 
          even as your memory refuses to offer even a sliver of recall to what you had seen.
          <br /><br />
          Take <b>2d6 damage and -1d6 to all rolls in the next room</b>, as the echoes of madness fade from your mind.          
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
