import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/Dark Horsemen.mp3";
import banner from "../../res/Gelatinous_Cube.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl =
  "https://i.pinimg.com/736x/7a/89/ca/7a89cafaafae1a392dd232588eec8350.jpg";

const PARAMS = { floors: 6 };

var nextRooms;

const DejaVu22 = () => {
  const [floor, setFloor] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    PARAMS.floors = Math.ceil(Math.max(Math.random() * 6, 2));
    nextRooms = GetNextRooms(DejaVu22_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(DejaVu22_Obj);
  }

  const parseInput = () => {
    setFloor(floor + 1);
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    component = <Flight rooms={nextRooms} floor={floor} output={parseInput} />;
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Illusory Cave</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The portal closes behind you, and you enter into what looks to be an 
        underground cave system; the walls are bereft of anything, scrubbed 
        clean of any dust or loose dirt, leaving only cold stone. 
        The only source of light in the hallway are magical lights, hanging 
        dimly in the air, scattered about along with portals.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={DejaVu22_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(DejaVu22_Obj.route);
      }}
    >
      <img src={DejaVu22_Obj.image} />
      <span className="entryText">The portal opens to a dark cave leading into the unknown.</span>
    </button>
  );
};

export var DejaVu22_Obj = {
  name: "DejaVu22",
  room: DejaVu22,
  entry: Entryway,
  route: "/halloween2023/dejavu22",
  exits: 6,
  prob: 5,
  image: doorUrl,
};

export default DejaVu22;

//input rooms
const Flight = (props) => {
  const submitHandler = (e) => {
    e.preventDefault();
    props.output();
  };

  const getFloorText = () => {
    if (props.floor == 0) {
      return (
        <p>
          As your eyes adjust to the darkness, you can the hallway extends in two directions; 
          at one end, the tunnel has collapsed, rubble blocking the way, and at the other you see...<br />
          <br />
          Yourself, being chased by a gelatinous wall of green slime. The other you only just 
          manages to snatch a hefty iron key from the cube's interior and rush through an 
          iron door in the moments before the slime slams into the door.<br />
          <br />
          Then, the slime begins to rush back towards you - you only have a 
          few moments to try and make it through a portal before the slime will engulf you.<br />
          <br />
          You turn to run, and in a moment you pass a portal; you can either take this one,
          or continue rushing down the hallway to the next portal, but if you keep going,
          the cube will keep you from turning back.
        </p>
      );
    } else if (props.floor >= PARAMS.floors - 1) {
      return (
        <p>
          There's no portals past this one - you must take this one, lest the cube devour you whole.
        </p>
      );
    } else {
      return (
        <p>
          There's still space left in the tunnel - but the gelatinous cube is 
          right on your heels, so close you can't take even a single step backwards. 
          If you pass this portal by, you won't be able to return to it.
        </p>
      );
    }
  };

  //console.log(PARAMS.floors);

  return (
    <div>
      <div className="roomText">
        {getFloorText()}
        {props.floor < PARAMS.floors - 1 ? (
          <button className="inputSubmit" onClick={submitHandler}>
            Keep running!
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="nextHolder">{props.rooms[props.floor].entry()}</div>
    </div>
  );
};
