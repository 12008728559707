import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/spectre.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Spectre = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Spectre_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Spectre_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "optionOne":
        changeDisplay("optionOnePass");
        break;
      case "optionTwo":
        changeDisplay("optionTwoPass");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOnePass":
        component = <PassOptionOneView rooms={nextRooms} />;
        break;
      case "optionTwoPass":
        component = <PassOptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Spectre</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">An empty room with an inhabitant.</p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Spectre_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Spectre_Obj.route);
      }}
    >
      <img src={Spectre_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Spectre_Obj = {
  name: "Spectre",
  room: Spectre,
  entry: Entryway,
  route: "/halloween2023/spectre",
  exits: 2,
  prob: 1,
  image: doorUrl,
};

export default Spectre;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([option]);
          break;
        case "optionTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <div className="roomText">
        As you enter the room, you find yourself in a room patterned after 
        one of the elements; dust has long accumulated on every surface, 
        and in one corner you spy a manacled skeleton, a ghost floating above it.<br /><br />
        Describe this person in your roleplay, and describe your conversation
        with them.
        <br />
        <br />
        Criteria:
        <ul>
          <li>They have been here for thousands of years.</li>
          <li>They have been trapped here by the manacles around their ankles, binding their spirit here even in death.</li>
          <li>Only you may release them - and only you can guide them out of the maze.</li>
          <li>They will beg you to release them, offering hidden treasure or what little they remember as payment.</li>
          <li>They are terrified from the maze beginning to collapse and will lie about what they can offer in order for you to free them.</li>
        </ul>
      </div>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Do you free them, or leave them here to continue languishing?
        </label>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Free Them
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Leave Them
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassOptionOneView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>You decide to free them - with the clatter of the manacles opening, their spirit detaches from their corpse and attaches to you.</b> 
          <br />
          Roleplay them accompanying you for the remainder of this session in the maze; 
          if you die, their spirit is lost within the maze forevermore. 
          <br />
          As a result of the difficulty posed by bearing their spirit,{" "}
          <b style={{ color: "red" }}>
            you suffer -2 on all rolls until you leave the maze or they leave your company.
          </b>
          <br />
          <br />
          <b>
             At any time, you may sever their connection to you, devouring 
             what little vitality they still possess to gain +10 to your rolls 
             in one room.  
          </b>{" "}
          After this, their spirit is permanently destroyed.
          <br />
          <br />
          If you successfully manage to escape, they are immensely grateful 
          and whisper a secret to you before vanishing as their soul finds rest. 
          DM QM: NORTHEAST If you manage to escape with them.
          <br />
          <br />
          You see two exits to the room.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const PassOptionTwoView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          
          <b>You decide to not free them, whatever your reasons.</b> The spirit may beg or bargain,
          threaten or beguile, but their shackles leave them trapped and unable to harm you.
          <br />
          <br />
          You see two exits to the room.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
