import { useEffect, useState } from "react";
import defaultSong from "../res/music/Down Time Piano.wav";
import { Entrance_Obj } from "./entrance";
import { Exit_Obj } from "./exit_rooms/exit";

import Player, { OneShot } from "../gui/player";
import DifficultyMeter from "../gui/difficultyMeter";
import { Outlet } from "react-router-dom";

import { Abomination_Obj } from "./death_rooms/abomination.js";
import { BlackHole_Obj } from "./death_rooms/blackhole.js";
import { Bodies_Obj } from "./death_rooms/bodies.js";
import { Circus_Obj } from "./death_rooms/circus.js";
import { Iridescence_Obj } from "./death_rooms/iridescence.js";
import { Madness_Obj } from "./death_rooms/madness.js";
import { Malnourished_Obj } from "./death_rooms/malnourished.js";
import { Tentacles_Obj } from "./death_rooms/tentacles.js";
import { Trial_Obj } from "./death_rooms/trial.js";
import { Whale_Obj } from "./death_rooms/whale.js";

import { AlterPrison_Obj } from "./odd_rooms/alterprison.js";
import { Amethyst_Obj } from "./odd_rooms/amethyst.js";
import { CloseEyes_Obj } from "./odd_rooms/closeeyes.js";
import { Dejavu20_Obj } from "./odd_rooms/dejavu20.js";
import { Diamond_Obj } from "./odd_rooms/diamond.js";
import {  Emerald_Obj} from "./odd_rooms/emerald.js";
import { Forget_Obj } from "./odd_rooms/forget.js";
import { Gold_Obj } from "./odd_rooms/gold.js";
import { Observatory_Obj } from "./odd_rooms/observatory.js";
import { Ruby_Obj } from "./odd_rooms/ruby.js";
import { Sapphire_Obj } from "./odd_rooms/sapphire.js";
import { Snatcher_Obj } from "./odd_rooms/snatcher.js";
import { Spectre_Obj } from "./odd_rooms/spectre.js";

import { Columns_Obj } from "./passage_rooms/columns";
import { DejaVu22_Obj } from "./passage_rooms/dejavu22";
import { HofLost_Obj } from "./passage_rooms/hoflost";
import { Library_Obj } from "./passage_rooms/library";
import { Nautilus_Obj } from "./passage_rooms/nautilus";
import { Nexus_Obj } from "./passage_rooms/nexus";
import { Resonator_Obj } from "./passage_rooms/resonator";
import { RReality_Obj } from "./passage_rooms/rreality";
import { Rubble_Obj } from "./passage_rooms/rubble";
import { Void_Obj } from "./passage_rooms/void";

import { Abyss_Obj } from "./skill_rooms/abyss";
import { Correspondence_Obj } from "./skill_rooms/correspondence";
import { Frequency_Obj } from "./skill_rooms/frequency";
import { Friend_Obj } from "./skill_rooms/friend";
import { Gravity_Obj } from "./skill_rooms/gravity";
import { Guard_Obj } from "./skill_rooms/guard";
import { Harmony_Obj } from "./skill_rooms/harmony";
import { Hofmirrors_Obj } from "./skill_rooms/hofmirrors";
import { Holewall_Obj } from "./skill_rooms/holewall";
import { Lecture_Obj } from "./skill_rooms/lecture";
import { Moonmadness_Obj } from "./skill_rooms/moonmadness";
import { Obelisk_Obj } from "./skill_rooms/obelisk";
import { Symbols_Obj } from "./skill_rooms/symbols";

import { LightKey_Obj } from "./key_rooms/lightKey";
import { DarkKey_Obj } from "./key_rooms/darkKey";
import { WaterKey_Obj } from "./key_rooms/waterKey";
import { WindKey_Obj } from "./key_rooms/windKey";

export const GlobalVars = {
  level: 1,
  depth: 0,
  difficultyRamp: 0.5,
  maxRamp: 10,
  maxDifficulty: 40,
  baseDiff: [6, 8, 10, 12, 14, 16],
  keys: 0,
};

export const Rooms = {
  passage_rooms: {
    name: "passage",
    rooms: [
      Columns_Obj,
      DejaVu22_Obj,
      HofLost_Obj,
      Library_Obj,
      Nautilus_Obj,
      Nexus_Obj,
      Resonator_Obj,
      RReality_Obj,
      Rubble_Obj,
      Void_Obj,
    ],
    chance: 50,
    startChance: 50,
    endChance: 10,
    total: 0,
  },
  odd_rooms: {
    name: "odd",
    rooms: [
      AlterPrison_Obj,
      Amethyst_Obj,
      CloseEyes_Obj,
      Dejavu20_Obj,
      Diamond_Obj,
      Emerald_Obj,
      Forget_Obj,
      Gold_Obj,
      Observatory_Obj,
      Ruby_Obj,
      Sapphire_Obj,
      Snatcher_Obj,
      Spectre_Obj,
    ],
    chance: 30,
    startChance: 30,
    endChance: 15,
    total: 0,
  },
  skill_rooms: {
    name: "skill",
    rooms: [
      Abyss_Obj,
      Correspondence_Obj,
      Frequency_Obj,
      Friend_Obj,
      Gravity_Obj,
      Guard_Obj,
      Harmony_Obj,
      Hofmirrors_Obj,
      Holewall_Obj,
      Lecture_Obj,
      Moonmadness_Obj,
      Obelisk_Obj,
      Symbols_Obj,
    ],
    chance: 30,
    startChance: 30,
    endChance: 50,
    total: 0,
  },
  death_rooms: {
    name: "death",
    rooms: [
      Abomination_Obj,
      BlackHole_Obj,
      Bodies_Obj,
      Circus_Obj,
      Iridescence_Obj,
      Madness_Obj,
      Malnourished_Obj,
      Tentacles_Obj,
      Trial_Obj,
      Whale_Obj,
    ],
    chance: 20,
    startChance: 20,
    endChance: 50,
    total: 0,
  },
  key_rooms: {
    name: "key",
    rooms: [
      LightKey_Obj,
      DarkKey_Obj,
      WaterKey_Obj,
      WindKey_Obj,
    ],
    chance: 0,
    startChance: 0,
    endChance: 7,
    total: 0,
  },
  exit: {
    name: "exit",
    rooms: [Exit_Obj],
    chance: 20,
    startChance: 20,
    endChance: 10,
    total: 0,
  },
};

export const GetRandomRoom = () => {
  //select the type of room.
  try {
    let total = 0;
    for (const set in Rooms) {
      if (Rooms[set].rooms.length > 0) {
        total += Rooms[set].chance;
        Rooms[set].total = total;
      } else {
        Rooms[set].total = 0;
      }
    }
    let rand = Math.random() * total;
    let type = Rooms.exit;
    for (const set in Rooms) {
      if (rand < Rooms[set].total) {
        type = Rooms[set];
        break;
      }
    }
    //console.log(type);

    //select the individual room.
    total = 0;
    for (let i = 0; i < type.rooms.length; i++) {
      if (type.rooms[i]) {
        total += type.rooms[i].prob;
        type.rooms[i].total = total;
      }
    }
    rand = Math.random() * total;
    let chosenRoom = Rooms.exit.rooms[0];
    for (let i = 0; i < type.rooms.length; i++) {
      if (rand < type.rooms[i].total) {
        chosenRoom = type.rooms[i];
        break;
      }
    }
    //console.log(chosenRoom);
    //console.log(Rooms);
    return chosenRoom;
  } catch (e) {
    console.log(e);
  }
};

export const GetRoomOfType = (type) => {
  let room;
  let counter = 0;
  let setType;
  for (const set in Rooms) {
    if (Rooms[set].name == type) {
      setType = Rooms[set];
      break;
    }
  }
  if (!setType) {
    throw "No rooms of type: " + type;
  }

  while (!room && counter < 20) {
    room = setType.rooms[Math.floor(setType.rooms.length * Math.random())];
    counter++;
  }

  if (!room) {
    return GetRandomRoom();
  } else {
    return room;
  }
};

export const GetNextRooms = (current) => {
  //get the next rooms in the rotation.
  let next = [];
  let counter = 0;
  while (next.length < current.exits && counter != 20) {
    let temp = GetRandomRoom();
    if (
      !next.includes(temp) &&
      temp != current &&
      !(
        Rooms.key_rooms.rooms.includes(current) &&
        Rooms.exit.rooms.includes(temp)
      )
    ) {
      next.push(temp);
    }
    counter += 1;
  }

  //remove the current room from the rotation, if applicable.
  if (
    !Rooms.exit.rooms.includes(current) &&
    !Rooms.passage_rooms.rooms.includes(current)
  ) {
    for (const a in Rooms) {
      const temp = Rooms[a].rooms.indexOf(current);
      if (temp > -1) {
        Rooms[a].rooms.splice(temp, 1);
        break;
      }
    }
  }

  //reset depth
  if (current == Entrance_Obj) {
    GlobalVars.depth = 0;
  } else {
    GlobalVars.depth += 1;
  }

  updateRoomChances();

  return next;
};

export const getDifficulty = (tier) => {
  let difficulty = GlobalVars.baseDiff;
  let level = GlobalVars.level;
  if (level <= 5) {
    difficulty = GlobalVars.baseDiff;
  } else if (level <= 10) {
    difficulty = [8, 10, 12, 14, 16, 18];
  } else if (level <= 15) {
    difficulty = [8, 10, 13, 16, 19, 23];
  } else {
    difficulty = [10, 12, 16, 20, 23, 28];
  }
  return (
    difficulty[tier] +
    Math.min(
      Math.floor(GlobalVars.depth * GlobalVars.difficultyRamp),
      GlobalVars.maxRamp
    )
  );
};

export const updateRoomChances = () => {
  const depth = GlobalVars.depth;
  const keys = GlobalVars.keys;
  var d = Math.min((depth * GlobalVars.difficultyRamp) / GlobalVars.maxRamp, 1);

  for (const type in Rooms) {
    Rooms[type].chance =
      Rooms[type].startChance +
      (Rooms[type].endChance - Rooms[type].startChance) * d;
  }

  if (
    depth < GlobalVars.maxRamp / (GlobalVars.difficultyRamp * 2) &&
    keys > 0
  ) {
    GlobalVars.depth = GlobalVars.maxRamp / (GlobalVars.difficultyRamp * 2);
  }

  switch (keys) {
    case 1:
      Rooms.key_rooms.endChance = 1;
      Rooms.exit.endChance = 6;
      Rooms.exit.startChance = 10;
      break;
    case 2:
      Rooms.key_rooms.endChance = 0.5;
      Rooms.exit.endChance = 3;
      Rooms.exit.startChance = 10;
      break;
    case 3:
      Rooms.key_rooms.endChance = 0.25;
      Rooms.exit.endChance = 1.5;
      Rooms.exit.startChance = 10;
      break;
    case 4:
      Rooms.key_rooms.endChance = 0.125;
      Rooms.exit.endChance = 0.75;
      Rooms.exit.startChance = 10;
      break;
    case 5:
      Rooms.key_rooms.endChance = 0;
      Rooms.exit.endChance = 0.325;
      Rooms.exit.startChance = 10;
      break;
    default:
      break;
  }
};

export const changeSong = (url) => {
  const player = document.getElementById("music");
  if (player && player.src.indexOf(url) == -1) {
    player.src = url;
    player.volume = 0.5;
  }
};

export const playOneShot = (url) => {
  const player = document.getElementById("oneShot");
  if (player && player.src != url) {
    player.src = url;
    player.volume = 0.5;
  }
};

const CurrentRoom = () => {
  return (
    <div className="roomHolder">
      <Player song={""} />
      <OneShot />

      <Outlet />
    </div>
  );
};

export default CurrentRoom;
