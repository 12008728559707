import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/abomination.png"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Abomination = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Abomination_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Abomination_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Abomination</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room you enter into is dank and dark, must and humidity hanging in the air 
      along with the scent of spoiled meat. Sundered limbs line the floor around you, and a few yards off, just before 
      the world fades to darkness, you spot a shattered and broken pair of manacles and chains.</p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Abomination_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Abomination_Obj.route);
      }}
    >
      <img src={Abomination_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Abomination_Obj = {
  name: "Abomination",
  room: Abomination,
  entry: Entryway,
  route: "/halloween2023/abomination",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Abomination;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">Dark ichor leaks from the walls and ceiling, and red threads of chaos magic striate as the world dissolves away - the maze is collapsing under Alethea's influence and her corruption is manifest. <br /><br />
      
      You only have moments to catch your bearings before some monstrosity composed of limbs and fangs and claws rushes upon you from the shadows and you must flee. The ground shakes and primal terror rises to the surface of your mind as your body aches from exertion.
      <br /><br />
      Make three Agility (Dexterity) or Endurance (Body) rolls to try and outrun the abomination.
      </p>
      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Rolls
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> It's a desperate dash to make it to the end, and your lungs burn from exertion and from the burning scent of rot - but you outrun the beast until its primal roars are left as a distant echo, 
          three portals placed in front of you, offering you paths forward and onwards.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> Your feet fumble through the mounds of foetid flesh and the world spins as you fall to the floor; you find yourself pinned by an errant, too-human arm, and you feel a ripping sensation. Your body begins to be torn apart as your awareness fades, the amalgamated 
          monstrosity in your peripheral vision feasting on what used to be your body.
        </div>
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
