import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/bodies.png"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Bodies = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Bodies_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Bodies_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Writhing Bodies</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">The air is thick with the disgusting scent of rotting meat and long congealed blood and bile. The ground beneath you is covered in an impenetrable carpet of maggot infested meat and bleached bones, 
      the source of the noxious smell that makes your belly churn and your head pound. </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Bodies_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Bodies_Obj.route);
      }}
    >
      <img src={Bodies_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Bodies_Obj = {
  name: "Bodies",
  room: Bodies,
  entry: Entryway,
  route: "/halloween2023/bodies",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Bodies;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText"> The limbs below you fragment and grow, humanoid and animal and formed of ooze and moving plaster.<br /><br /> 
      Mangled limbs twitch and spasm, and the pile of corpses seem to breathe anew below you. Bodies begin to crawl over each other as they surge upwards, grasping for you in an attempt to pull you down into them.
      Where they touch, biting cold, blisters and frost-burns bud on your skin.
      <br /><br />
      Make a Stealth (Dexterity) or Agility (Dexterity) roll to evade their hunger and avoid being clawed downwards and joined to the masses.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the result of your check below.
          <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> It's a ceaseless, rapid effort to make it away from the bodies, to avoid the limbs that seek to snuff you out and draw you into themselves. 
          You dart and dash and weave until at last your feet move from flesh to tile and portals offer you passage forwards.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> The clawing limbs work their way up your legs, tearing your clothes to shreds and covering you in blisters and burns as you're swallowed up by the pile of uncountably many corpses. 
          
          You sink, down, down, down into the ceaslessly moving mass of flesh, and it closes over you, smothering you on all sides as the light fades and you vanish from sight.
        </div>
      </div>


      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
