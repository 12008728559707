import "./difficultyMeter.css";
const MAX_RGB = [255, 0, 0];
const MIN_RGB = [0, 255, 0];

const DifficultyMeter = (props) => {
  const d = props.value;
  var a = (d * props.ramp) / props.maxRamp;
  var text = "It's Quiet";
  const rgb = [0, 0, 0];

  if (a > 1) {
    a = 1;
  }

  if (a <= 0.25) {
    text = "It's Quiet";
  } else if (a <= 0.5) {
    text = "Hearing Things";
  } else if (a <= 0.75) {
    text = "Heart Beating Faster";
  } else {
    text = "DEATH COMES FOR YOU";
  }

  for (let i = 0; i < rgb.length; i++) {
    rgb[i] = MIN_RGB[i] + (MAX_RGB[i] - MIN_RGB[i]) * a;
  }

  const barStyle = {
    backgroundColor: "rgb(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ")",
    width: a * 100 + "%",
  };

  return (
    <div className="difHolder">
      <div style={barStyle} className="difBar"></div>
      <span className="textHolder">
        Depth: {d} ({text})
      </span>
    </div>
  );
};

export default DifficultyMeter;
