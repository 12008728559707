import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/void_portal.jpg";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/special2.ogg";
import banner from "../../res/void.jpg"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Void = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Void_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Void_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
      case "partTwo":
        changeDisplay("partTwo");
        break;
      case "partThree":
        changeDisplay("partThree");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne output={parseInput} />;
        break;
      case "partTwo":
        component = <PartTwo output={parseInput} />;
        break;
      case "partThree":
        component = <PartThree rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Endless Void</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        It's ... nothing. Empty. Black. Void of light and figure and sound, 
        so quiet you hear the dull roar of your heartbeat in your ears. 
        Distantly off, you see forms squirm in the unshadows - like something 
        that shouldn't be given purchase in our reality.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Void_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Void_Obj.route);
      }}
    >
      <img src={Void_Obj.image} />
      <span className="entryText">
        You see nothing through the portal but endless void.
      </span>
    </button>
  );
};

export var Void_Obj = {
  name: "Void",
  room: Void,
  entry: Entryway,
  route: "/halloween2023/void",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Void;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You walk and walk and walk, your muscles aching and your mouth dry. 
        There's no sound, no echo, no sensation, no up or down. 
        Time itself seems to fade - you find it impossible to gauge 
        how long you've been here.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Trudge onwards - everything hurts.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        What was it like, before? You find it increasingly difficult to remember 
        what it was like, before you came to this place. Perhaps this is all 
        you've ever known, the endless darkness and the ceaseless walking.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Trudge onwards.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartTwo = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partThree":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        Onwards, onwards, ever onwards; the shadows tug at your form, 
        as if you should have never been here. Hours stretch into years, 
        with no hope of abatement or release from the darkness; there 
        may be entire months where you sit, unmoving, before you resume 
        your futile efforts to escape, or perhaps your spirit is unbowed 
        - perhaps you are stubborn.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Onwards.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PartThree = (props) => {
  return (
    <div>
      <div className="roomText">
        Bereft of hope, robbed of any potential to escape, deprived of food and drink 
        - you think yourself one of the specters roaming the temple, sometimes. 
        What other explanation would there be, to have spent decades wandering in the 
        dark with no food or drink or rest - or perhaps you perished long ago, 
        and this is merely purgatory. <br />
        <br />
        Then... light, after eons! <br />
        <br />
        You see the first true flecks of light; perhaps you race, or perhaps you hobble. 
        Perhaps you fall to your knees in front of the portals you have found outwards, 
        or perhaps you leap through - in whatever case, you have found an exit.<br />
        <br />
        This time at least.
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
