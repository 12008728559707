import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/hoflost.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const HofLost = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(HofLost_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(HofLost_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Hall of the Lost</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Shades and wraiths are the only occupants of this hall, former victims 
        of either the maze or the Devalaya, staring unblinking as you pass. 
        They do not react, simply watch as you advance onwards.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={HofLost_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(HofLost_Obj.route);
      }}
    >
      <img src={HofLost_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var HofLost_Obj = {
  name: "HofLost",
  room: HofLost,
  entry: Entryway,
  route: "/halloween2023/hoflost",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default HofLost;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        Dust lies thick underfoot, caskets and coffins sealed shut decorating the 
        alcoves, plaques that would indicate the occupants worn smooth by the passage of time.<br /><br />
        Darkness drips from the ceiling as black ichor, and a few tombs stand out - the placques fresh, indicating those you love; within the coffins you will find their body, horribly mutilated and indicative of a painful and agonizing death.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Continue onwards.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");

  return (
    <div>
      <p className="roomText">
        Finally, you find three tombs ornately decorated with precious metals 
        and gemstones; the tops have been blown off, and within each lies a portal.
      </p>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
