import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/iridescence.jpg"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Iridescence = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Iridescence_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Iridescence_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Iridescence</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">Mist pools around your ankles, thick ribbons of vapor and magic rising from the floor in tendrils 
      before sinking back into the mass. On all sides, you are surrounded by opaque white ice, like an impenetrable wall.</p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Iridescence_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Iridescence_Obj.route);
      }}
    >
      <img src={Iridescence_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Iridescence_Obj = {
  name: "Iridescence",
  room: Iridescence,
  entry: Entryway,
  route: "/halloween2023/iridescence",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Iridescence;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Something moves, surging beneath the surface - or perhaps, like something caged, it lives in the mirror. Darkness dances across the ice, spiderwebbing like fractured glass as corruption sinks into the surface.
        <br /><br />
        You get a better look at it - your reflection moves to the surface, before erupting outwards and lunging for you.
        <br /><br />
        Make an Athletics (Body) or Unarmed (Body or Dexterity) roll to overpower your doppleganger physically, 
        or a Mental Acuity roll to overpower them mentally and gain the upper hand in that way.</p>
      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the result of your check below.
          <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> Both will and strength force them into submission, bit by bit, until they lay unmoving on the floor beneath you; you are deeply wounded, but victorious over the doppleganger.
          <br /><br />
          The ground is stained with blood as you rise, panting - and in the wounds you dealt to the being that you overcame, you see a secret written in an alphabet of scars.
          <br /><br />
          "Seven times seven you will find me,<br />
          Striking the wall painfully,<br />
          Down the barrier fell at late,<br />
          Sealing the town's dreadful fate."<br /><br />

          As the mist slowly fades, three portals materialize in front of you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You move, but your dopple moves quicker - you act, but they seem to anticipate every move. Bit by bit they overpower you, until with a final strike, you double over.<br />
          <br />
          They take this opportunity to turn you around and shove you into the ice - you fall through, and when you attempt to leave, you find yourself unable. Everything is horribly cold behind the icy mirror, and slowly your life fades.
        </div>
      </div>


      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/possession");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
