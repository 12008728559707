import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/Midnight_Hour.ogg";
import banner from "../../res/emerald.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Emerald = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Emerald_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Emerald_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Emerald Forest</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Endless, dazzling green, like a time long since past that you 
        might never return to, like old-growth forests unsullied and unblemished. 
        If secrets kept had a color, it might be this, impossible to pierce in its splendor.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Emerald_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Emerald_Obj.route);
      }}
    >
      <img src={Emerald_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Emerald_Obj = {
  name: "Emerald",
  room: Emerald,
  entry: Entryway,
  route: "/halloween2023/emerald",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Emerald;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">When your eyes adapt, you are in a forest 
      - but these trees are like nothing you've ever heard, dead languages 
      and forgotten pacts whispered where chilly wind rustles their branches. But... <br /><br />
      In the whispered secrets you hear horrible, terrible truths - everything you've ever feared confirmed in the cacophany, everything you hate brought to the forefront, everything you love disproven.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Listen to the Whispers
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Shut Your Ears
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      You open your mind and your ears, inviting in the forgotten secrets found here - much of what you hear escapes, but a few trickles of knowledge remain. Something that might help - and something else.
      <br /><br />
      "Seven times seven you will find me,<br />
      Spoken of disparagingly,<br />
      Words filled with such bitter hate,<br />
      At being unable to find your fate."
      <br /><br />
      Take -2 to all Mind rolls until you leave the maze, but gain the ability to reroll any one roll - when you are using this, you gain a flash of foresight that helps you potentially avoid falling victim to a room's dangers. You lose this ability if you leave the maze before using it.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Search for a portal.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You shut your ears and hurry onwards, blocking out the whispers until you find the portals that will take you forward.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Hurry and find a way forward.
        </button>
      </div>
    </div>
  );
};
