import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/whale.jpg"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Whale = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Whale_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Whale_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Eyes and Maw</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">You venture forth and the world becomes filled with some invisible, thick liquid - you breathe and air reaches your blood, but by the planes it's a heaving effort to take even a single breath. 
      You swim along, the stars glinting in the distance as you progress along what looks to be a rough floor.</p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Whale_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Whale_Obj.route);
      }}
    >
      <img src={Whale_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Whale_Obj = {
  name: "Whale",
  room: Whale,
  entry: Entryway,
  route: "/halloween2023/whale",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Whale;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">Only, after what could be minutes or hours, the floor begins to shift, undulating and moving; a section squirms before splitting, revealing a gargantuan eye. 
      Empires could be swallowed up by the iris and the expanse of space warbles and murmurs as the colossal creature becomes aware of you. 
      <br /><br />
      You spot portals a ways off - and the scaled floor splits, revealing an abyssal maw lined with odd, vertical teeth interwoven with squirming threadlike protrusions, poised to swallow you whole. The threads are stained with dull red and inky black - the colors of darkness and old blood.
      <br /><br />
      Make three Endurance (Body), Athletics (Body), or Mental Acuity rolls to muster enough strength to escape.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Rolls
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You paddle away from the beast in broad strokes, muscles burning as you strain against the honey-like viscosity you find yourself trapped in. 
          You make enough distance to arrive at the portals with enough time to race through.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You push your body as hard as you can, mustering every last drop of strength you posess - but it's not enough. 
          Your efforts fail as your limbs slow, and only moments later you pass through the gaps in the titan's teeth. 
          You grab ahold of one and desperately attempt to escape, but the threads wrap around you like a vice, preventing you from fleeing.
        </div>
      </div>


      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
