import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/alterprison.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const AlterPrison = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(AlterPrison_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(AlterPrison_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Prison</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You pass through a prison; most of the cells are empty, but one or two have hunched figures in them, mostly unmoving where they sit in the corners. One of them looks up at you.
      </p>
      <p className="roomText">The distorted face bears an inhuman smile and you feel a cold shiver run down your spine.
      <br /><br />
      The lights go out, and the bars shatter - you can hear them break and feel something rush at you, slamming you against the wall. You feel insects under your skin and a violent grip that leaves bruises on you and only just barely doesn't snap bones. When they come back on, the smiling creature is gone.</p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={AlterPrison_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(AlterPrison_Obj.route);
      }}
    >
      <img src={AlterPrison_Obj.image} />
      <span className="entryText">A portal ringed with gently glowing light.</span>
    </button>
  );
};

export var AlterPrison_Obj = {
  name: "AlterPrison",
  room: AlterPrison,
  entry: Entryway,
  route: "/halloween2023/alterprison",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default AlterPrison;
