import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/Midnight_Hour.ogg";
import banner from "../../res/diamond.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Diamond = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Diamond_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Diamond_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Diamond Expanses</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Every facet of glass in the world you step into resonates with the light of a sun - 
        like daybreak or noonshine or the last dregs of illumination at dusk. 
        Multicolored and dazzling, it shines through you till your bones glow like crystal beneath your glass skin.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Diamond_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Diamond_Obj.route);
      }}
    >
      <img src={Diamond_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Diamond_Obj = {
  name: "Diamond",
  room: Diamond,
  entry: Entryway,
  route: "/halloween2023/diamond",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Diamond;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        The light is terrible in its beauty, like scoring gamma rays or the boiling, 
        invisible brilliance of microwaves. After the darkness of the maze, it's almost too much to bear.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Remember the Sun
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Snuff Out the Light
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      You have seen the sun - the true one. You call on its memory, like a thunderbolt, like the clash of a symphony, like the remembrance of an old lover. The world around you explodes as the memory sears its way out, and the shattering of glass leaves you alone in a room of windows and portals, true daylight cresting through the glass.
      <br /><br />
      And, where the memory burst forth, a secret has seared its way onto you.
      <br /><br />
      "Seven times seven you will find me,<br />
      Fear building increasingly,<br />
      Rattling together as you wait,<br />
      To find the climax of your fate."
      <br /><br />
      Take -3 to ALL rolls until you leave the maze, but after you finish a room, you can tweak the threads of probability surrounding one of the doors, transforming it into an exit from the maze that allows you to leave, even if there isn't an exit on your screen. You lose this ability if you leave the maze before using it.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Find a portal in the growing light.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You thunder and reach out, and the glass shatters around you; 
        you snuff out the light and silence the tyranny of false-dawn, 
        leaving you alone in a room of rapidly cooling silver, the last 
        flecks of light beginning to fade from the shattered glass as a portal appears.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Step through the portal.
        </button>
      </div>
    </div>
  );
};
