import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/Midnight_Hour.ogg";
import banner from "../../res/ruby.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Ruby = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Ruby_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Ruby_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Ruby Cavity</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Red and gold, blood and ichor, rubies and coin. Like the wound of some 
        gargantuan creature larger than anything that could have ever lived, 
        you are coated in crimson, forbidden and succulent. 
        The air is thick with cloying sweetness, and maggots crawl over every inch of exposed skin.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Ruby_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Ruby_Obj.route);
      }}
    >
      <img src={Ruby_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Ruby_Obj = {
  name: "Ruby",
  room: Ruby,
  entry: Entryway,
  route: "/halloween2023/ruby",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Ruby;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
      The maggots crawling over everything stink of rot, and the millions of bees that were here prior lie dead on the floor. <br /><br />
      There is a burnt corpse here, sat at a table across from you - three bowls have been prepared to recieve food. In feasting, they feasted not on flesh or blood, but on memory.
      <br /><br />
      Your wounds, too, are a kind of memory.
      <br /><br />
      Here, in the dark, you might feast richly on that memory, and find healing.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Feast
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Spurn the Meal
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      Like soup and sweetness and honeysuckle, you fill your bowl with the wounds of those who came before, of the cavity and your own wounds. The richness is delectable, and you find something wriggling at the bottom of your bowl - something precious. Something wriggles in your stomach as well.
      <br /><br />
      "Seven times seven you will find me,<br />
      With no key to find entry,<br />
      Before the time has drawn too late,<br />
      Open me up to find your fate."
      <br /><br />
      Take -2 to all Body rolls until you leave the maze, but refill your HP to full.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Keep moving, hunger sated.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        It is only some falsehood, some make believe figment - 
        you refuse to play along. For a moment, the world ebbs - 
        will establishes itself, the blood fades, and you find yourself before another portal.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          See where it leads.
        </button>
      </div>
    </div>
  );
};
