import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/rreality.png";
import door from "../../res/generic_door.png";

import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const RReality = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(RReality_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(RReality_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 0;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Shattered Reality</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The world around you devolves into a tapestry of shorn 
        portals and a breakdown of space.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={RReality_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(RReality_Obj.route);
      }}
    >
      <img src={RReality_Obj.image} />
      <span className="entryText">A portal ringed by shaking, uncertain light.</span>
    </button>
  );
};

export var RReality_Obj = {
  name: "RReality",
  room: RReality,
  entry: Entryway,
  route: "/halloween2023/rreality",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default RReality;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You try stepping through one portal and find yourself ten feet back, 
        you peek through another and find yourself looking at your own back. The abyss mocks you and your heart races, every second spent lingering in the dark turning a small portion of your body to stone, slowly petrifying you. Threads of red chaos magic dance in the void and writhe as even the darkness, the nothingness seems to fall away. Trying to make sense of what's left behind causes you to go mad, your sanity slipping and your body warping with alien geometries.
        <br />
        <br />
        Make a Mysticism (Mind) or Spirituality (Spirit) roll to 
        escape the broken segment of maze you find yourself in.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your roll below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: input the result in all boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>

        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You manage to focus the chaotic magicks around you enough to slip free 
          from the snare you found yourself caught in. Only one path lies before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You attempt to focus the magicks, but a tiny misalignment leaves you 
          free of the snare - and free of one your limbs.<br />
          <br />
          <b>Take 3 damage and lose a random limb.</b> If you survive, only one path stands before you.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead.
      </button>
    </div>
  );
};
