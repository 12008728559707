import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/Midnight_Hour.ogg";
import banner from "../../res/amethyst.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Amethyst = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Amethyst_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Amethyst_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Amethyst Bridge</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Like a jeweled beetle in the harsh desert sun, like a 
        carcass on the butcher block, like a dream, pinned to memory by ink and paper. 
        You step out onto the bridge, dazzlingly amethyst, like some long forgotten glory.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Amethyst_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Amethyst_Obj.route);
      }}
    >
      <img src={Amethyst_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Amethyst_Obj = {
  name: "Amethyst",
  room: Amethyst,
  entry: Entryway,
  route: "/halloween2023/amethyst",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Amethyst;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">Solar winds whistle around you. A star burns beneath the bridge, nimbus guttering like a croaking, mocking laugh - darkness infects it, like the star might collapse into an endless void. You erode under the radiation, like a mountain cleft in twain by shifting plates. </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Cross Beneath Star's Gaze
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Sneak Past Unseen
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      Forging onwards, the alien radiations wick at your being, and you are left somehow both less and more than before, as if leaving something behind and taking something away in its place. Your skin is the faintest bit more leathery now, and you find a secret etched somewhere only you would think to look.
      <br /><br />
      "Seven times seven you will find me,<br />
      Space's bridge and icy sea,<br />
      Blood and wood around await,<br />
      To guide the path on to your fate."
      <br /><br />
      Take -2 to all Dexterity rolls until you leave the maze, but the next time you would roll a Natural 1, you may choose to instead treat it as if you had rolled a Natural 20 on that roll; this manifests as a surge of sudden luck in RP. You lose this ability if you leave the maze before using it.
      <br /><br />
      In front of you is a single portal
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Step through the portal.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You wait until the passage of a planetoid obscures your position, casting elongated shadows that conceal your passage. You make it across, unharmed and unchanged.
        <br /><br />
        In front of you is a single portal.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Step through the portal.
        </button>
      </div>
    </div>
  );
};
