import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/Midnight_Hour.ogg";
import banner from "../../res/sapphire.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Sapphire = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Sapphire_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Sapphire_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Sapphire Corridor</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You step through, and the world is bathed in sapphire. 
        Icy walls of the gemstone glint, cruel and unforgiving around you. 
        Beyond, the light of the celestial expanse shines, devoid of mirth and kindness.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Sapphire_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Sapphire_Obj.route);
      }}
    >
      <img src={Sapphire_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Sapphire_Obj = {
  name: "Sapphire",
  room: Sapphire,
  entry: Entryway,
  route: "/halloween2023/sapphire",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Sapphire;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">The corridor seems to stretch on forever, till the horizon tapers to a single point and the world forgets how to breathe. This is a hostile place.
      <br /><br />
      You should not be here.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step Through the Wall
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Trudge Onward
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      If you press against the sapphire, so brittle and cold, you will spill into the expanse of space. The void is hungry, your flesh burns till you wake, still in the endless corridor - in place of the broken hole now stands your path forward, and the burns have seared a secret into your flesh.
      <br /><br />
      "Seven times seven you will find me,<br />
      A diamond hall awaits thee,<br />
      Iridescence, find the gate,<br />
      To build or break your final fate."
      <br /><br />
      Take -2 to all Spirit rolls until you leave the maze, but you may choose one room to add 1d6 to the result of all your rolls after rolling them; this manifests as a sudden surge in the attributes being tested by the rolls. You lose this ability if you leave the maze before using it.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Head through the opening.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You trek onwards till your skin is specked with liver spots, till 
        your hair is gray and your muscles weak. You pursue the horizon till 
        you lay down on the floor and your eyes shut. Just a moment of rest. <br /><br />
        Just a moment.
        <br /><br />
        When you wake, you are at the end. You reach up and touch your face, 
        and it's as if no time has passed. Perhaps it was merely a dream.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Make for the portal.
        </button>
      </div>
    </div>
  );
};
