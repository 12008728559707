import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/moonmadness.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Moonmadness = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Moonmadness_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Moonmadness_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Moonmadness</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You step through the portal to a town clad in mist, the scent of decaying meat and shrill, maddened screams hanging in the air. A moon hangs overhead emitting a distorting green light that causes your skin to itch and your mind to roil and heave.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Moonmadness_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Moonmadness_Obj.route);
      }}
    >
      <img src={Moonmadness_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Moonmadness_Obj = {
  name: "Moonmadness",
  room: Moonmadness,
  entry: Entryway,
  route: "/halloween2023/moonmadness",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Moonmadness;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You feel it under your skin - you need to claw at it, to peel it away; you are filthy with mortality, and you must claw away that filth.
        <br /><br />
        Roll three Mental Acuity rolls to resist the urge.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> You resist the urge to peel away your skin, and as you look around you spot a shaded alcove where two portals gently hum.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  const lb = [];
  for (let i = 0; i < 40; i++) {
    lb.push(<br key={i} />);
  }

  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You fail to resist the urge, and the first claw of your hands on your skin feels like coming up for air after drowning. 
          You claw at your skin and peel it away.
          <br /><br />
          After your skin you peel away your muscles.
          <br /><br />
          And after your muscles are removed you begin prying out your bones.
          <br /><br />
          Eventually you regain your sanity; you take <b>8 damage</b> from the wounds you inflicted on yourself under the maddening moonlight. 
          If this doesn't kill you, you at last spot two portals in a shaded alcove, thrumming faintly.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
