import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/nexus_portal.jpg";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/nexus.jpg"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Nexus = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Nexus_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Nexus_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Portal Nexus</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You step through the portal and now stand on a slab of checkerboarded 
        basalt and marble - solar winds whistle around you, stars glinting 
        faintly in the distance like jewels set against the endless void. 
        Five portals circle you, just before the tile falls away to the 
        endless void of space.
      </p>
      <p className="roomText">The expanse of space is strated by darkness as stars begin to blink out one by one. Choose a path forward.</p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Nexus_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Nexus_Obj.route);
      }}
    >
      <img src={Nexus_Obj.image} />
      <span className="entryText">Stars glint coldly through the swirling edges of the portal.</span>
    </button>
  );
};

export var Nexus_Obj = {
  name: "Nexus",
  room: Nexus,
  entry: Entryway,
  route: "/halloween2023/nexus",
  exits: 5,
  prob: 5,
  image: doorUrl,
};

export default Nexus;
