import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/forget.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Forget = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Forget_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Forget_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Hungering Void</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You find yourself in an abyssal void. Hunger roars from the darkness, 
        demanding your knowledge and secrets, like the murmurs of some gargantuan 
        creature's stomach or the ache of starvation after not eating for weeks.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Forget_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Forget_Obj.route);
      }}
    >
      <img src={Forget_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Forget_Obj = {
  name: "Forget",
  room: Forget,
  entry: Entryway,
  route: "/halloween2023/forget",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Forget;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">What you give to the void will be forever lost. Appease it, and you may gain a boon. How much of your being can you give up, while still remaining yourself?</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Leave the Abyss
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Make a Sacrifice
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You feel its touch linger in your soul, trailing after you with a crippling hunger that only burns more for its appetites being spurned. Take 1d4 damage as the hunger sears through you.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Hurry through the dark to the next room.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      Give the abyss what it craves, submit part of your mind to it - the abyss is hungry and it takes everything offered, and more; you are unable to stop it as it devours rempantly. There's a brief hollow in your soul, like the echo of wind in spindled, leaf-barren branches, that quickly vanishes. You gain +3 to all rolls for the next twenty-four hours.
      <br /><br />
      You do not remember giving to the void. You will never remember what is lost. 
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Hurry through the dark to the next room.
        </button>
      </div>
    </div>
  );
};
