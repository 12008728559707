import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/obeliskportal.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/obelisk.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GetRoomOfType,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Obelisk = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Obelisk_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Obelisk_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 5;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 1; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (input[0] == "turnBack") {
      changeDisplay("turnBack");
    } else if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "turnBack":
        component = <TurnBack rooms={nextRooms} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Ancient Obelisk</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A six sided obelisk rises from the floor, covered in intricate, unfamiliar words; each side is formed of a different gemstone and ancient, deep magic pulses from the structure. The magic emanating from it is so potent, so powerful, that it tugs at the essence of your being and begins to consume you.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Obelisk_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Obelisk_Obj.route);
      }}
    >
      <img src={Obelisk_Obj.image} />
      <span className="entryText">
        You can see some sort of obelisk through the portal.
      </span>
    </button>
  );
};

export var Obelisk_Obj = {
  name: "Obelisk",
  room: Obelisk,
  entry: Entryway,
  route: "/halloween2023/obelisk",
  exits: 2,
  prob: 2,
  image: doorUrl,
};

export default Obelisk;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if ((!isNaN(input) && !isNaN(input2) && !isNaN(input3)) || option !== "") {
      setErrText("");
      props.output([option, input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You sense deep, profound meaning in the symbols however - enough that risking yourself might be worth it for what you might learn. The obelisk seems to repel the dark ichor and veins of chaos magic that now infest the maze... for now.
        <br /><br />
        You can either roll three Academics (Mind) rolls to decipher the pillar, or turn away - portals thrum just beyond the obelisk, inviting you onwards.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="turnBack"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Turn Back
        </button>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> Though it's a struggle to decipher the primordial text, eventually you decode a few lines of text, as well as a spell. Speaking the spell causes reality to shift and warp, before a new portal opens before you.<br /><br />
          
          "Seven by seven will guide you true,<br />
          Seven by seven names and virtues.<br /><br />
          
          One was hidden through frequencies false,<br />
          A world that never was where you walked.<br /><br />
          
          One was the gate forged of corrupted ice,<br />
          With riddle of light to open and claim the prize.<br /><br />
          
          One was the lake of deepest black,<br />
          Unclaimed now in the maze to hold you back.<br /><br />
          
          One was the monoliths of ancient make,<br />
          With an interloper new a place to take.<br /><br />

          One is hidden in sight so plane,<br />
          Seek at home a crystal to claim."
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">{GetRoomOfType("key").entry()}</div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You attempt to decode the obelisk, but your efforts are in vain; you feel parcels of yourself gradually being torn away, your mind suffering and your body withering. Eventually, it's too much to bear, and you move past the obelisk to the two awaiting portals.{" "}
          <br /><br />
          <b>Suffer -1d6</b> on your next skill roll, and for the next 1d4 rooms you take 1d4 damage on entering the room.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const TurnBack = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          You hurry past the obelisk to where two portals await.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
