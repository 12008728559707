import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Death from "./rooms/death";
import Possession from "./rooms/possession";

import Entrance from "./rooms/entrance";
import Exit from "./rooms/exit_rooms/exit";

import CurrentRoom from "./rooms/room_handler";

import Abomination from "./rooms/death_rooms/abomination";
import Blackhole from "./rooms/death_rooms/blackhole";
import Bodies from "./rooms/death_rooms/bodies";
import Circus from "./rooms/death_rooms/circus";
import Iridescence from "./rooms/death_rooms/iridescence";
import Madness from "./rooms/death_rooms/madness";
import Malnourished from "./rooms/death_rooms/malnourished";
import Tentacles from "./rooms/death_rooms/tentacles";
import Trial from "./rooms/death_rooms/trial";
import Whale from "./rooms/death_rooms/whale";

import AlterPrison from "./rooms/odd_rooms/alterprison";
import Amethyst from "./rooms/odd_rooms/amethyst";
import CloseEyes from "./rooms/odd_rooms/closeeyes";
import Dejavu20 from "./rooms/odd_rooms/dejavu20";
import Diamond from "./rooms/odd_rooms/diamond";
import Emerald from "./rooms/odd_rooms/emerald";
import Forget from "./rooms/odd_rooms/forget";
import Gold from "./rooms/odd_rooms/gold";
import Observatory from "./rooms/odd_rooms/observatory";
import Ruby from "./rooms/odd_rooms/ruby";
import Sapphire from "./rooms/odd_rooms/sapphire";
import Snatcher from "./rooms/odd_rooms/snatcher";
import Spectre from "./rooms/odd_rooms/spectre";

import Columns from "./rooms/passage_rooms/columns";
import DejaVu22 from "./rooms/passage_rooms/dejavu22";
import HofLost from "./rooms/passage_rooms/hoflost";
import Library from "./rooms/passage_rooms/library";
import Nautilus from "./rooms/passage_rooms/nautilus";
import Nexus from "./rooms/passage_rooms/nexus";
import Resonator from "./rooms/passage_rooms/resonator";
import RReality from "./rooms/passage_rooms/rreality";
import Rubble from "./rooms/passage_rooms/rubble";
import Void from "./rooms/passage_rooms/void";

import Abyss from "./rooms/skill_rooms/abyss";
import Correspondence from "./rooms/skill_rooms/correspondence";
import Frequency from "./rooms/skill_rooms/frequency";
import Friend from "./rooms/skill_rooms/friend";
import Gravity from "./rooms/skill_rooms/gravity";
import Guard from "./rooms/skill_rooms/guard";
import Harmony from "./rooms/skill_rooms/harmony";
import Hofmirrors from "./rooms/skill_rooms/hofmirrors";
import Holewall from "./rooms/skill_rooms/holewall";
import Lecture from "./rooms/skill_rooms/lecture";
import Moonmadness from "./rooms/skill_rooms/moonmadness";
import Obelisk from "./rooms/skill_rooms/obelisk";
import Symbols from "./rooms/skill_rooms/symbols";

import LightKey from "./rooms/key_rooms/lightKey";
import DarkKey from "./rooms/key_rooms/darkKey";
import WaterKey from "./rooms/key_rooms/waterKey";
import WindKey from "./rooms/key_rooms/windKey";

//import DoorsRiddle from "./rooms/key_rooms/doorsRiddle";


const RouterHandler = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/halloween2023" replace />} />
        <Route path="*" element={<Navigate to="/halloween2023" replace />} />
        <Route path="halloween2023" element={<CurrentRoom />}>
          <Route index element={<Entrance />} />
          <Route path="abomination" element={<Abomination />} />
          <Route path="blackhole" element={<Blackhole />} />
          <Route path="bodies" element={<Bodies />} />
          <Route path="circus" element={<Circus />} />
          <Route path="iridescence" element={<Iridescence />} />
          <Route path="madness" element={<Madness />} />
          <Route path="malnourished" element={<Malnourished />} />
          <Route path="tentacles" element={<Tentacles />} />
          <Route path="trial" element={<Trial />} />
          <Route path="whale" element={<Whale />} />
          <Route path="exit" element={<Exit />} />
          <Route path="alterprison" element={<AlterPrison />} />
          <Route path="amethyst" element={<Amethyst />} />
          <Route path="closeeyes" element={<CloseEyes />} />
          <Route path="dejavu20" element={<Dejavu20 />} />
          <Route path="diamond" element={<Diamond />} />
          <Route path="emerald" element={<Emerald />} />
          <Route path="forget" element={<Forget />} />
          <Route path="gold" element={<Gold />} />
          <Route path="observatory" element={<Observatory />} />
          <Route path="ruby" element={<Ruby />} />
          <Route path="sapphire" element={<Sapphire />} />
          <Route path="snatcher" element={<Snatcher />} />
          <Route path="spectre" element={<Spectre />} />
          <Route path="columns" element={<Columns />} />
          <Route path="dejavu22" element={<DejaVu22 />} />
          <Route path="hoflost" element={<HofLost />} />
          <Route path="library" element={<Library />} />
          <Route path="nautilus" element={<Nautilus />} />
          <Route path="nexus" element={<Nexus />} />
          <Route path="resonator" element={<Resonator />} />
          <Route path="rreality" element={<RReality />} />
          <Route path="rubble" element={<Rubble />} />
          <Route path="void" element={<Void />} />
          <Route path="abyss" element={<Abyss />} />
          <Route path="correspondence" element={<Correspondence />} />
          <Route path="frequency" element={<Frequency />} />
          <Route path="friend" element={<Friend />} />
          <Route path="gravity" element={<Gravity />} />
          <Route path="guard" element={<Guard />} />
          <Route path="harmony" element={<Harmony />} />
          <Route path="hofmirrors" element={<Hofmirrors />} />
          <Route path="holewall" element={<Holewall />} />
          <Route path="lecture" element={<Lecture />} />
          <Route path="moonmadness" element={<Moonmadness />} />
          <Route path="obelisk" element={<Obelisk />} />
          <Route path="symbols" element={<Symbols />} />
          <Route path="death" element={<Death />} />
          <Route path="possession" element={<Possession />} />
          <Route path="gate" element={<LightKey />} />
          <Route path="lake" element={<DarkKey />} />
          <Route path="monolith" element={<WaterKey />} />
          <Route path="throne" element={<WindKey />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouterHandler;
