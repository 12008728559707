import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/symbols.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Symbols = () => {
  const [display, changeDisplay] = useState("pre");
  const [pass, setPass] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Symbols_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Symbols_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    setPass(successes);
    changeDisplay("pass");
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView successes={pass} rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Symbols</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room is bathed in a ferric red glow that casts strange shadows behind any objects, and your eyes take several moments to adjust to the  blood-red light of the room. A translucent wall stands at the midpoint of the room, beyond which you can see two portals gently thrumming - the wall is covered in ancient, weathered glyphs that seem to radiate malevolence as they writhe and shift.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Symbols_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Symbols_Obj.route);
      }}
    >
      <img src={Symbols_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Symbols_Obj = {
  name: "Symbols",
  room: Symbols,
  entry: Entryway,
  route: "/halloween2023/symbols",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Symbols;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Before the wall, atop a decayed and partially dismantled mahogany lectern, is a heavy leatherbound book filled with the same archaic symbols as those on the wall. There seems to be no path forward save for the portals beyond the wall, and the longer you stare at the symbols the more your vision blurs and your mind fills with echoed whispers in ancient and forgotten tongues. Darkness writhes across the room in thick ropes of shadow and red striations of chaos magic work through the physical segments of the room, leaving nothing - not even darkness or void - in their wake.
        <br /><br />
        Make three Observation (Perception) or Spirituality (Spirit) rolls to read through the book and find a way past the wall.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          A piercing headache strikes you as you begin to read through the book; 
          the voices grow progressively more intense with each symbol you read. 
          It's a delicate dance of glancing up from the book to match the symbols 
          within to those on the walls, and in the process of searching for a passage to 
          knock the wall down you take{" "}
          {3 - props.successes > 0 ? (
            <b style={{ color: "red" }}>{3 - props.successes}d10</b>
          ) : (
            <b style={{ color: "green" }}>No</b>
          )}{" "}
          Damage! 
          <br /><br />
          Roleplay your success or death. If you are still alive, the wall 
          falls and you have two portals before you, allowing you to proceed.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
