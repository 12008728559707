import "./Demonic.css";
import { useState } from "react";

const script = [
  { text: "Pavana", translation: "The fragment is a light violet and seems to shake slightly in an unseen wind." },
  { text: "Agni", translation: "The fragment is a searing red and swelteringly hot to the touch." },
  { text: "Apah", translation: "The fragment is a deep, oceanic blue and seems to slowly drip water." },
  { text: "Buira", translation: "The fragment is jet black and seems to absorb light that strikes it." },
  { text: "Atapik", translation: "The fragment is a brilliant white and seems to shed light out to ten feet." },
  { text: "Rithvi", translation: "The fragment is a mossy green and seems to emanate the scent of deep, rich loam." },
  { text: "Vidyut", translation: "The fragment crackles with electricty and strands of scarlet chaos magic." },

];

const DemonScript = () => {
  const [randIndex] = useState(Math.floor(Math.random() * script.length));
  const [randShow] = useState(Math.random());
  return randShow < 1 ? (
    <div className="demonText">
      You see a fragment of some sort of crystal stuck into the floor, 
      embedded as if it struck the ground at great speed. Into the crystal 
      are etched a number of rough letters that seem to shift and sway as you look 
      at them, as if you weren't meant to read them. Below them are etchings that you are 
      able to understand.<br /><br />

      The crystal gives off a powerful amount of energy, and you seem to be able to take it - 
      if you choose.
      <br />
      <b>Etching:</b> {script[randIndex].text}
      <br />
      <b>Description:</b> {script[randIndex].translation}
      <br /><br />
      If you escape with this fragment, DM QM : NORTHEAST to tell him as much - make sure you record the etching that it was engraved with.
    </div>
  ) : (
    ""
  );
};

export default DemonScript;
