import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/keyportal.jpg";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Abandoned Building.mp3";
import placque from "../../res/thegate.jpg";
import banner from "../../res/thegate.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const LightKey = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(LightKey_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(LightKey_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "optionOne":
        changeDisplay("optionOnePass");
        break;
      case "pushOpen":
        GlobalVars.keys += 1;
        changeDisplay("pushOpen");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput rooms={nextRooms} output={parseInput} />;
        break;
      case "optionOnePass":
        component = <PartOne output={parseInput} />;
        break;
      case "pushOpen":
        component = <PushOpen rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Gate</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You step through the portal and find yourself in an endless cave formed of ice, 
        darkness having sunken into the ice to create veins of blackfrost, and absorbing some of the light that seems to emanate from sections of uncorrupted ice. 
        Your feet crunch in the snow that builds in drifts atop the frozen surface of the ground.
        <br /><br />
        The few cracks in the cave roof reveal an abyssal sky devoid of stars.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={LightKey_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(LightKey_Obj.route);
      }}
    >
      <img src={LightKey_Obj.image} />
      <span className="entryText">A portal wreathed in warm radiance.</span>
    </button>
  );
};

export var LightKey_Obj = {
  name: "LightKey",
  room: LightKey,
  entry: Entryway,
  route: "/halloween2023/gate",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default LightKey;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("notVamp");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    let password = "Knock";
    console.log(input2);
    if (input.toLowerCase() == password.toLowerCase()) {
      setErrText("");
      props.output([option]);
    } else {
      setErrText("The wind whistles across the ice; you take 1 damage through DR. Perhaps try something else?");
    }
  };

  return (
    <div>
      <p className="roomText">
        You step forward, and find yourself before a imposing gate formed of ice and shadow; burning symbols are etched in frostfire up and down the entire surface, two massive figures like resin born of ice standing watch like gargoyles or watchdogs. The gate stands tall, reaching skyward, and the frigid wind that blows passes through you as easily as through the leaves of a tree.
        <br /><br />
        The gate itself is glassy, unreflective as it stands - you can see nothing in the ebonfrost as dark as obsidian.
      </p>
      
      <p className="roomText">
        Set into the surface of the frozen pillar to the right of the gate are seven indents, like plaques were removed from the spaces; the top six seem to have been removed, leaving only the seventh and final one remaining, etched into golden glass.
        <br /><br />
        "Seven times seven you will find me,<br />
        This is the riddle posed to thee,<br />
        To see who speaketh here at late,<br />
        One word to open and find your fate.
        <br /><br />
        What am I?"
        <br /><br />
        Scattered about in the snow drifts are three portals - you could take them to leave, if you don't know the answer to the riddle.

      </p>
      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          One word to open. What is it?
        </label>
        <input
          className="inputField"
          type="text"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Speak and open the gate.
        </button>
        <label className="error">{errText}</label>
      </form>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "pushOpen":
          GlobalVars.keys += 1;
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> As you speak the word out loud, a knocking sound 
          echoes across the surface of the gate seven times. The ice begins to 
          crack, glowing from within with immense, celestial light, like the rupture 
          of a star. For a moment, you glow from within, like stained glass painted across your skin.
          <br />
          <br />
          You glimpse something beautiful through the gate as the light crescendoes - 
          an orchard, trees plump with fruits in gold and silver and platinum. Vitality pulses 
          from them as the gate continues to sing, and the sun overhead bathes the world in rainbows 
          so sublime tears come to your eyes.
          <br />
          <br />
          On the branch closest to you, leaning close to the gate, one of the fruits has crystallized.
        </div>
      </div>
      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="pushOpen"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Pluck the crystalline fruit.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PushOpen = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          The fruit comes away in your hand, a gemstone the size of a melon, 
          and the singing light of the gate begins to fade. In only moments, 
          it quiets to a hum, and as the song fades, so does the light. In moments the 
          gate is gone, and the only thing left of the sublime vistas is the crystal you 
          now hold in your hand, pulsing with magical power.
          <div className="keyAcquire">
            The crystal crumbles away in your hand, leaving you with seven multicolored fragments and five rasps. The fragments 
            are part of the final crystal you will need - if you manage to escape the maze with them, DM QM : NORTHEAST to tell him as much.
          </div>
          Once the light has faded enough for you to see again, you are faced with three portals.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
