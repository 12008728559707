import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/malnourished.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Malnourished = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Malnourished_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Malnourished_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Malnourished Feast</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">Fabric flutters around you, the warm scent of something ferric and heavy hanging in the air, rich and resplendant like marrow or fat. 
      You press forward, but your belly growls - how long has it been since you last ate? </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Malnourished_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Malnourished_Obj.route);
      }}
    >
      <img src={Malnourished_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Malnourished_Obj = {
  name: "Malnourished",
  room: Malnourished,
  entry: Entryway,
  route: "/halloween2023/malnourished",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Malnourished;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
        <p className="roomText"> Ichor drips from the ceiling onto the feast, the tables overflowing onto the floor in an avalanche of meat and succulent vegetables and fruit; red chaos magic dances across the room like lightning in a storm.<br /><br /> 
        A table piled high stands in front of you, and the growling intensifies. Something lingering beneath the surface of your skull cries out a warning. 
        <br /><br />
        Make three Mental Acuity or Endurance (Body) rolls to resist the urge to feast.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Rolls
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> It is horribly difficult to resist the allure of the feast, and every food seems so sumptuous and delicious that you might weep from sorrow to leave it behind - 
          but you resist, and you escape without claiming that poisoned prize.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> The scent is delicious, like milk and honey and vanilla, choice meats and black pepper and saffron. Like your favorite food and drink in abundance. 
          A seat at the table and a plate piled high, the fork and the knife. You feast and consume, you eat and eat and eat till you feel yourself swell.
          Every bite you take of the food on your platter, you feel in your own flesh; every succulent mouthful comes from your own body.
        </div>
      </div>
 

      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
