import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/columns_door.jpg";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/columns.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

const PARAMS = { floors: 6 };

var nextRooms;

const Columns = () => {
  const [floor, setFloor] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    PARAMS.floors = Math.ceil(Math.max(Math.random() * 6, 2));
    nextRooms = GetNextRooms(Columns_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Columns_Obj);
  }

  const parseInput = () => {
    setFloor(floor + 1);
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    component = <Flight rooms={nextRooms} floor={floor} output={parseInput} />;
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Catacombs</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Catacombs adorn the walls, and tunnels mark the way - the tunnels seem 
        to stretch on for miles, far past your ability to see. You notice five 
        pillars within this room, that seem to be made from bone and flesh, 
        and still breathe. 
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Columns_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Columns_Obj.route);
      }}
    >
      <img src={Columns_Obj.image} />
      <span className="entryText">Through the portal you see catacombs.</span>
    </button>
  );
};

export var Columns_Obj = {
  name: "Columns",
  room: Columns,
  entry: Entryway,
  route: "/halloween2023/columns",
  exits: 6,
  prob: 5,
  image: doorUrl,
};

export default Columns;

//input rooms
const Flight = (props) => {
  const submitHandler = (e) => {
    e.preventDefault();
    props.output();
  };

  const getFloorText = () => {
    if (props.floor == 0) {
      return (
        <p>
          Onwards you move, searching for portals, when you hear grinding. 
          Spinning about, you find that in the time it took you to move down 
          one tunnel, the breathing pillars have advanced onwards towards you. 
          If you attempt to double back, they lean from the wall, threatening touch. 
          You can only move away from them - they do not move so long as you watch.<br />
          <br />
          You notice something distressing - the pillars are formed of impressions of your loved ones, faces trapped in horrified, terrified screams forever trapped in bone and stone.<br />
          <br />
          You can either take the portal you've found yourself near, or keep going;
          if you keep going, you will be unable to turn back.
        </p>
      );
    } else if (props.floor >= PARAMS.floors - 1) {
      return (
        <p>
          You feel your back bump up against the catacomb wall - the tunnel 
          off to your left is your only path forward and away from the pillars. 
          Mercifully, there is a portal at the end.
        </p>
      );
    } else {
      return (
        <p>
          You glance over your shoulder after looking off to one side and 
          spying a portal; there's a few more tunnels left. When you look back, 
          one of the pillars is inches from your face, and you can see outlined 
          in it hundreds of eye-sockets the size of sewing needles.<br />
          <br />
          You can either take the portal you've found yourself near, or keep going;
          if you keep going, you will be unable to turn back.
        </p>
      );
    }
  };

  //console.log(PARAMS.floors);

  return (
    <div>
      <div className="roomText">
        {getFloorText()}
        {props.floor < PARAMS.floors - 1 ? (
          <button className="inputSubmit" onClick={submitHandler}>
            Continue Downwards
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="nextHolder">{props.rooms[props.floor].entry()}</div>
    </div>
  );
};
