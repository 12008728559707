import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/The Ether.wav";
import banner from "../../res/dejavu20.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Dejavu20 = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Dejavu20_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Dejavu20_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Illusory Conservatory</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
      The portal closes behind you, leaving you in what appears to be a greenhouse; 
      behind you, where the door stood, stands a door rapidly being overrun with vines and leaves, 
      sealing it shut.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Dejavu20_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Dejavu20_Obj.route);
      }}
    >
      <img src={Dejavu20_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Dejavu20_Obj = {
  name: "Dejavu20",
  room: Dejavu20,
  entry: Entryway,
  route: "/halloween2023/dejavu20",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Dejavu20;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
      You only just catch a glimpse of it, but you spot a person vanish through a secret door behind a wall of vines - perhaps they're a stranger, or perhaps they're someone you know.
      <br /><br />
      You can either choose to follow them, or exit the greenhouse, into a vast field of flowers.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Secret Door
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Field of Flowers
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      You brush the vines aside and move onwards, though you don't catch sight of whoever passed 
      through the door prior to you. The nearby pond ripples and shimmers as something moves beneath it 
      - feel free to RP your interactions with whatever is moving under the water before you make your way through the next portal.

      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Progress onwards.
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
      You exit into the garden, the moon shining down from overhead - 
      the ground underfoot is covered in flowers, and bushes similarly adorned in 
      roses and hydrangeas surround you. Glowing eyes peer out at you from the denser 
      sections of foliage - feel free to RP your interactions with them before you make 
      your way through the next portal.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Keep going.
        </button>
      </div>
    </div>
  );
};
