import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/exitportal.jpg";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/exit.ogg";
import banner from "../../res/entrance.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Exit = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const navigate = useNavigate();
  useEffect(() => {
    changeSong(song);
  }, []);

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Exit</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You leave the maze safely and return to the temple, but only narrowly make it through before the portal collapses swiftly and violently behind you. You must wait one hour before venturing in
        once more.
      </p>
      <p className="roomText">
        We hope you enjoyed your stay. If you have any keys, please report which
        keys you've earned to QM : NORTHEAST/Larethvar Lightwing. <br />
        <br />
        [Experience created by Larethvar Lightwing, Rosen Valdemar, Carissen Rixen, Adeline Khan, Erik Manley, and Farwulf Svarti]
      </p>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/");
        }}
      >
        Click here to return to the Entrance
      </button>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Exit_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Exit_Obj.route);
      }}
    >
      <img src={Exit_Obj.image} />
      <span className="entryText">You see a way out of the maze.</span>
    </button>
  );
};

export var Exit_Obj = {
  name: "Exit",
  room: Exit,
  entry: Entryway,
  route: "/halloween2023/exit",
  exits: 5,
  prob: 5,
  image: doorUrl,
};

export default Exit;
