import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/rubble.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Rubble = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Rubble_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Rubble_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Ruined Temple</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        All around you, a temple stands, having collapsed; skeletons lie in 
        the pews and on the floor and at the head of the hall an altar is stained 
        with blood. Windows of stained glass have shattered, the glass lying on 
        the floor, and cold stars glint through the open arches.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Rubble_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Rubble_Obj.route);
      }}
    >
      <img src={Rubble_Obj.image} />
      <span className="entryText">
        A portal ringed by gently glowing light.
      </span>
    </button>
  );
};

export var Rubble_Obj = {
  name: "Rubble",
  room: Rubble,
  entry: Entryway,
  route: "/halloween2023/rubble",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Rubble;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        Behind the altar you find provisions - perhaps some bottles of wine, fruits, 
        vegetables, or inexplicably well preserved meat. Whatever it is, it's light 
        and there's no one to stop you from taking it.
        <br /><br />
        But once you bite into the meat it is infested with maggots, once you bite into the fruit and vegetables they're filled with mold, the wine is spoiled to vinegar, the bread is stale and moldy. 
        <br /><br />
        The world falls apart, the ceiling covered in inky black veins of corruption, and everything seems to shift uneasily as the maze begins to fail.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Look for the portals.
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");

  return (
    <div>
      <p className="roomText">
        Down a hallway, you find three portals clustered around a skeleton clad in 
        ornate robes and fancy jewelry.
      </p>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
