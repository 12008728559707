import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/lecture.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Lecture = () => {
  const [display, changeDisplay] = useState("pre");
  const [pass, setPass] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Lecture_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Lecture_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    setPass(successes);
    changeDisplay("pass");
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView successes={pass} rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Lecture Hall</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You find yourself at the base of an indoor ampitheater, dusty 
        and decrepit tiers of chairs encircling you, facing you from all 
        directions. A single aisle rises up through the steppes of seats, 
        leading to a massive set of double doors at the top.

      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Lecture_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Lecture_Obj.route);
      }}
    >
      <img src={Lecture_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Lecture_Obj = {
  name: "Lecture",
  room: Lecture,
  entry: Entryway,
  route: "/halloween2023/lecture",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Lecture;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
          Although the room is empty, hisses and whispers of mockery and deception begin 
          to echo through the air. The seats are filled with biting remarks, hissed criticisms, 
          and cruel calls of disgust that echo around the chamber and fall down upon your ears like the clanging of cymbals.<br /> 
          They only seem to get louder as you move about, derogatory laughs and grunts cutting through, 
          watching and remarking on your every move, every thought. They get more violent, more angry, 
          more threatening as you rise up the staircase, to the next portals. 
          <br /><br />
          Dark ichor seeps from the roof, falling to the ground below, and the world crumbles and writhes and shifts where veins of angry red chaos magic move about.
          <br /><br />
           Make three Mental Acuity, Spirituality (Spirit), or Mysticism (Mind) rolls to ignore and push through the violent remarks of the angry mob of the lecture hall.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          You stumble up the stairs even as the words twist in your brain, 
          trying to force fears and insecurities to the forefront; moving onwards 
          causes the voices to become rageful and increasingly malevolant until you 
          eventually manage to make it to the end of the hall. You take{" "}
          {3 - props.successes > 0 ? (
            <b style={{ color: "red" }}>{3 - props.successes}d10</b>
          ) : (
            <b style={{ color: "green" }}>No</b>
          )}{" "}
          Damage! Roleplay your success or death. If you are still alive, you
          have two portals before you and can proceed.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
