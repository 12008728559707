import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/monoliths.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Harmony = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Harmony_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Harmony_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Harmonic Monoliths</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The air is thick and cloying with static that causes the hair on the back of your neck to rise; the floor is composed of brass, with decending steps that lead to an inset plateau in the middle of the room.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Harmony_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Harmony_Obj.route);
      }}
    >
      <img src={Harmony_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Harmony_Obj = {
  name: "Harmony",
  room: Harmony,
  entry: Entryway,
  route: "/halloween2023/harmony",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Harmony;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Surrounding the circular pit are massive obsidian monliths, each easily as tall as you; they're covered in shifting, ornate patterns, and emit an unsettling low pitched hum. Icy to the touch, the vibrations resonate with the core of your being. As you search for an exit, the frequencies begin to rise, and you feel your body begin to unravel, torn apart by the dissonant emanations. Veins of chaos magic leap from them to you, bouncing off where Alethea's corrupted energies fail to change you.
        <br /><br />
        Roll three Performance (Charisma) or Academics (Mind) rolls to harmonize the frequencies before they tear you apart.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> As you harmonize the music of the monoliths, the hum resolves into a low, mournful symphony; light suffuses one side of the room as two portals appear.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> You fail to match the frequency, and the dissonant hum becomes a violent shriek that makes your entire body hurt and drops you to your knees. Solving the puzzle takes much longer as you feel your organs shifting under the influence of the monoliths and you take 2d6 damage. If you're still alive, by the time the portals open and you finish the puzzle, your head feels like someone has lobotomized you with a metal rod.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
