import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/gold.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GetRandomRoom,
  GlobalVars,
  playOneShot,
  Rooms,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Gold = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Gold_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Gold_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        let rasps = input[0];
        GlobalVars.depth += rasps * 2;
        if (GlobalVars.depth * GlobalVars.difficultyRamp > GlobalVars.maxRamp) {
          GlobalVars.maxRamp = GlobalVars.depth * GlobalVars.difficultyRamp;
        }
        changeDisplay("optionOnePass");
        break;
      case "optionTwo":
        changeDisplay("optionOneFail");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOnePass":
        component = <PassOptionOneView rooms={nextRooms} />;
        break;
      case "optionOneFail":
        component = <FailOptionOneView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Treasure Hoard</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        As you step through the portal, you're dazzled by the light reflecting 
        off every surface in shades of gold and inky black. You hear the clanging of metal 
        and the clatter of things sliding over each other in waves before you see it. 
        The familiar black coins of the Syndicate, in piles enough to fund empires 
        strewn about the room, so high that you can't even see the tops of the hills 
        as they vanish into the darkness.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Gold_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Gold_Obj.route);
      }}
    >
      <img src={Gold_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var Gold_Obj = {
  name: "Gold",
  room: Gold,
  entry: Entryway,
  route: "/halloween2023/gold",
  exits: 1,
  prob: 0.5,
  image: doorUrl,
};

export default Gold;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([input, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
          There are far, far too many rasps here to take them all - but you can 
          take some, becoming fabulously wealthy in the process. You may choose 
          up to 20 rasps to take with you; if you manage to leave the castle 
          with any rasps from this room, message QM : NORTHEAST and he will 
          ensure they're added to your character.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          
          You may insert a number between 1 and 20 to fill your pockets and then
          attempt to leave, or you can immediately attempt to leave.
        </label>
        <input
          className="inputField"
          type="number"
          min="1"
          max="20"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Take Some Rasps
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Try to Find an Exit
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassOptionOneView = (props) => {
  let room;
  let counter = 0;
  while (!room && counter < 20) {
    room =
      Rooms.death_rooms.rooms[
        Math.floor(Rooms.death_rooms.rooms.length * Math.random())
      ];
    counter++;
  }
  //console.log(room);
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          You stuff some rasps into your pockets. If you manage to escape 
          with these rasps, message QM : NORTHEAST and he will submit the rasps. 
          <br /><br />
          You dig through the piles of remaining rasps, trying to find an exit. 
          Finally, you find a portal to lead you onwards.
        </div>
      </div>
      <div className="nextHolder">
        {room
          ? room.entry()
          : props.rooms.map((room) => {
              return room.entry();
            })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionOneView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          You dig through the piles of rasps, trying to find an exit, but you do
          not take any of the rasps for yourself. Finally, you do find an exit.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
