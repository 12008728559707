import "./player.css";
import song from "../res/music/Down Time Piano.wav";

const Player = (props) => {
  let temp = props.song;
  if (!temp) {
    temp = "";
  }
  return (
    <div className="musicPlayer">
      <audio id="music" controls autoPlay loop>
        <source src={temp} type="audio/mpeg" />
        <source src={temp} type="audio/wav" />
        Your browser does not support audio
      </audio>
    </div>
  );
};

export const OneShot = (props) => {
  return (
    <div className="musicPlayer">
      <audio id="oneShot" autoPlay>
        <source src={""} type="audio/mpeg" />
        <source src={""} type="audio/wav" />
        Your browser does not support audio
      </audio>
    </div>
  );
};

export default Player;
