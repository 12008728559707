import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/madness.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Madness = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Madness_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Madness_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Gallery of Madness</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">Upon traversing the portal, you find yourself ensnared in a nightmarish tableau of eldritch horror. The gallery is awash in a sickly, otherworldly light that casts elongated, writhing shadows upon the grotesque works of art that line its walls. 
      The paintings are macabre works of chaos and torment, masterpieces of nightmarish composition painted in blood, bile, and other grisly materials. 
      <br /><br />
      Each canvas depicts scenes of unspeakable horror and madness—twisted, contorted figures with faces frozen in agonized expressions, their bodies grotesquely distorted, as if wracked by unseen, malefic forces. 
      Surreal landscapes are studded with grotesque creatures, and the very laws of reality seem to have unraveled within these cursed frames, impossible depths and motions captured within. The colors are a maddening cacophony of 
      sickly greens, blood-reds, and abyssal blacks, assaulting the senses and defying all reason. </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Madness_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Madness_Obj.route);
      }}
    >
      <img src={Madness_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Madness_Obj = {
  name: "Madness",
  room: Madness,
  entry: Entryway,
  route: "/halloween2023/madness",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Madness;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText"> The gallery's occupants grin at you wildly, each portrait someone lost or hated - darkness coils around the frames, and there's an impression of you being a cattle led to slaughter.<br /><br /> 
      As you gaze upon these nightmarish visions, your mind is assailed by an overwhelming sense of dread and disorientation. Whispers, both seductive and sinister, emanate from the paintings, beckoning you to lose yourself within their eldritch depths. 
      Shapes seem to move and writhe within the canvases, and the very walls of the gallery appear to pulse with a malevolent heartbeat.
      <br /><br />
      Make a Mental Acuity, Mysticism (Mind), or Spirituality (Spirit) roll to resist the whispers and ensaring forces.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the result of your check below.
          <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b> The sweet whispers turn acrid as you rouse yourself, like a dream turning to a nightmare. 
          You realize abruptly the terrible danger you are in as long as you linger here, and you rush down the wood paneled corridors until the whispers fade to backround noise. 
          <DemonScript />
          As your chest heaves, the whole thing seems surreal - like the portraits were blank and it was only a trick that led you to find them filled with anything. A shudder runs down your spine, and you press onwards.
        </div>
      </div>

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> The succorous enticement emanating from the gallery of profane paintings proves to be too much, and you find yourself drawn to a frame, 
          empty save for a gilded backdrop with a sickly green tinge.

          The title of the portrait is your name. 
          
          You reach a hand out, slowly, as if you were underwater or in a dream, and find the painting no more than a window through which your hand extends. 
          A sense of warmth and comfort propogates throughout your body, and you move closer, climbing inside. You face the world, and take your new station - you are not unhappy, 
          for the frame was made for you, and you are content here, in your new home in the gallery, mind enthralled by ancient enchantments and eldritch whispers. 
        </div>
      </div>


      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
