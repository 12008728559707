import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/normalportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/resonator.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Resonator = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Resonator_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Resonator_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Resonation</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        High pitched frequency ripples around you. Low hertz, and as you take a 
        step, the whining pitch grows shriller and shriller, increasingly 
        ear-splitting and liable to cause harm.
      </p>
      <p className="roomText">
        Moving onwards, the walls resonate in tandem with the pitch of the frequency. 
        By the time you find the portals the sound is keening, deafening. 
        Your ears will ring for hours after. Threads of red chaos magic writhe in time to the frequency and the world falls apart, turned to nothing where they make contact.
      </p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Resonator_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Resonator_Obj.route);
      }}
    >
      <img src={Resonator_Obj.image} />
      <span className="entryText">
        A portal ringed by gently glowing light.
      </span>
    </button>
  );
};

export var Resonator_Obj = {
  name: "Resonator",
  room: Resonator,
  entry: Entryway,
  route: "/halloween2023/resonator",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Resonator;
