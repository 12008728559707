import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/maintheme.mp3";
import banner from "../../res/fireKey.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const WindKey = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(WindKey_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(WindKey_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>A Throne Amidst Rubble</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a room made entirely from metal, a collapsed ceiling allowing sunlight 
        to stream into the space. A throne stands at the head of the room, rubble all around you - 
        who or whatever used to live in this room is long gone, leaving the space in disarray. <br /><br />
        Warmth rises from the ground gently, dispelling some of the chill that had sunken into you in the maze.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={WindKey_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(WindKey_Obj.route);
      }}
    >
      <img src={WindKey_Obj.image} />
      <span className="entryText">
        A portal ringed with gently glowing light.
      </span>
    </button>
  );
};

export var WindKey_Obj = {
  name: "WindKey",
  room: WindKey,
  entry: Entryway,
  route: "/halloween2023/throne",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default WindKey;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">On the throne you spot something - a cube, each side in jewel tones and with intricate carvings inlaid. <br /><br />
      
      As far as you can tell, there's nothing that would stop you from taking it - but something sinks into your stomach; there's a horrible sense of unease, like your reptilian brain screaming at you to run and get out before it's too late.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Claim the Cube
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Run
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        Your hands tremble and your stomach threatens to loose its contents as you approach - your mind screams and clammy, cold sweat runs down your forehead.
        <br /><br />
        Trying to touch the cube is like trying to bring yourself to sever your own limbs. It takes every ounce of strength you have - but you do it; your hands close around the cube and the sense of terror immediately abates.
        <div className="keyAcquire">
          The cube crumbles away in your hand, leaving you with seven multicolored fragments and five rasps. The fragments 
          are part of the final crystal you will need - if you manage to escape the maze with them, DM QM : NORTHEAST to tell him as much.
        </div>
        Three portals open up shortly after.
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        As you run from the throne, you feel an intense sense of relief and safety, and soon enough you find three portals.
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
