import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/deathportal.jpg";
import doorSfx from "../../res/sfx/deathportal.wav";
import song from "../../res/music/Carousel.wav";
import banner from "../../res/circus.jpg"
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Circus = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Circus_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Circus_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>The Circus</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">Your ankles are shackled to the floor as spectators take their seats, fulsome baskets of unidentifiable foodstuffs piled high in their laps. 
      The big top overhead is riddled with moth-holes, the red having discolored to a carmine-black and the white having been stained yellow with who knows what. </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Circus_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Circus_Obj.route);
      }}
    >
      <img src={Circus_Obj.image} />
      <span className="entryText">
        An ominous portal of pure darkness opens before you.
      </span>
    </button>
  );
};

export var Circus_Obj = {
  name: "Circus",
  room: Circus,
  entry: Entryway,
  route: "/halloween2023/circus",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Circus;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        The chatter of the crowd quiets and all eyes turn to you - so quiet you could hear a pin drop. There's a definite anxiety that sits in the pit of your stomach, 
        an awareness that if you displease them you won't make it out of the room. You swear you see Alethea in the stands for a moment, laughing at you, before she glitches and fades from being.
        <br /><br />
        Make a Performance (Charisma) or Persuasion (Charisma) roll to appease them sufficiently to get them to let you go.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the result of your check below.
          <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>SUCCESS!</b>  Laughter emanates from the stands at your act, and it seems they find you amusing enough to let you go; the shackles around your ankles pop open and portals open up around you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILURE!</b> They begin to jeer and boo, mocking your performance and throwing things - foodstuffs begin to slam against you as you struggle to stay upright, rapidly piling up. 
          Ankles, knees, waist - you rapidly are buried beneath spoiled meat and rotten tomatoes, until everything goes dark as the piling food covers the crown of your head.
        </div>
      </div>


      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2023/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
